import Vue from 'vue'
import App from './App.vue'
import './assets/styles/index.css'
import 'es6-promise/auto'
import VModal from 'vue-js-modal'
import Toasted from 'vue-toasted';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPenToSquare, faPlus, faCheck, faHome, faFileAlt, faRightFromBracket, faUsers, faUserTag, faEye, faEyeSlash, faMapMarkerAlt, faCircleQuestion, faGlobe, faTags, faUpload, faMagnifyingGlass, faFileExport, faLayerGroup, faBong, faBullseye, faFileArrowDown, faUniversity, faVectorSquare, faCube, faFileExcel, faFile, faImage, faFloppyDisk, faUser, faSort, faPhotoVideo, faChartPie, faCaretDown, faCaretUp, faPalette, faDownload, faBook, faArrowUpRightFromSquare, faCopy, faCaretLeft, faCaretRight, faCircleMinus, faLink, faUserTie, faUserGraduate, faHouseUser, faUserNinja, faUserFriends, faBookmark, faExpand, faBars, faSignsPost, faAddressBook, faBoxArchive, faFileLines, faLocationDot, faPhotoFilm, faBug, faMagnifyingGlassLocation, faMapLocationDot, faCompass, faBuildingColumns, faImages, faTrashCan, faRightToBracket, faBullhorn, faGear, faMapLocation, faBoreHole, faTimeline, faUpRightFromSquare, faMap, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LImageOverlay, LPolygon, LFeatureGroup, LWMSTileLayer, LControlLayers } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import VGeosearch from 'vue2-leaflet-geosearch';
import VueMeta from 'vue-meta'

import VSelect from '@/components/Select'
import VSelectMulti from '@/components/SelectMulti'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';

import Vidle from 'v-idle'

import i18n from './i18n'
import router from './router'
import store from './store'

import plugins from '@/plugins'

library.add(faTrashCan, faPenToSquare, faPlus, faCheck, faHome, faFileAlt, faRightFromBracket, faUsers, faUserTag, faEye, faEyeSlash, faMapMarkerAlt, faCircleQuestion, faGlobe, faTags, faUpload, faMagnifyingGlass, faFileExport, faLayerGroup, faBong, faBullseye, faFileArrowDown, faUniversity, faVectorSquare, faCube, faFileExcel, faFile, faImage, faFloppyDisk, faUser, faSort, faPhotoVideo, faChartPie, faCaretDown, faCaretUp, faPalette, faDownload, faBook, faArrowUpRightFromSquare, faSort, faCopy, faCaretLeft, faCaretRight, faGlobe, faCircleMinus, faLink, faUserTie, faUserGraduate, faHouseUser, faUserNinja, faUserFriends, faBookmark, faExpand, faUpRightFromSquare, faBars, faSignsPost, faImages, faAddressBook, faBoxArchive, faFileLines, faLocationDot, faPhotoFilm, faBug, faMagnifyingGlassLocation, faMapLocationDot, faCompass, faBuildingColumns, faRightToBracket, faBullhorn, faGear, faMapLocation, faBoreHole, faTimeline, faMap, faTriangleExclamation)
Vue.component('f-icon', FontAwesomeIcon)
Vue.component('vue-select', VueSelect)
Vue.component('vue-date-picker', DatePicker)
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-polygon', LPolygon);
Vue.component('l-popup', LPopup);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-feature-group', LFeatureGroup);
Vue.component('l-image-overlay', LImageOverlay);
Vue.component('l-wms-tile-layer', LWMSTileLayer);
Vue.component('l-control-layers', LControlLayers);
Vue.component('l-geosearch', VGeosearch);

// Fix missing marker icon import
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.component('VSelect', VSelect)
Vue.component('VSelectMulti', VSelectMulti)

Vue.config.productionTip = false
Vue.use(VModal, { 
  dynamic: true, 
  dynamicDefaults: { 
    clickToClose: false,
    scrollable: true
  },
  //injectModalsContainer: true,
}) //  dialog: true

Vue.use(Toasted,{
  duration: 6000,
  keepOnHover: true,
  className: 'v-toaster',
  //iconPack: 'material',
  theme: 'bubble',
  action : {
    text: 'x',
    onClick : (e, toastObject) => {
        toastObject.goAway(0);
    }
  },
})

Vue.use(VueMeta)
Vue.use(Vidle)

// global access to plugins data
Vue.mixin({
  data: function() {
    return {
      get plugins() {
        return plugins;
      }
    }
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')