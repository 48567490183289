<template>
  <div ref="spinner" :class="'loading-wrapper w-full h-full items-center left-0 top-0 bg-white bg-opacity-75 justify-center z-top ' + (toggle ? 'fixed' : 'hidden')">
    <div class="flex flex-col justify-center items-center top-1/2 w-full h-full">
      <div class="border-gray-300 h-[50px] w-[50px] animate-spin rounded-full border-8 border-t-teal-600"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    toggle: {
      type: Boolean,
      required: true
    }
  }
}
</script>