<template>
  <div class="crud-wrapper">
    <div class="box-content p-4 bg-slate-200 flex items-center justify-between rounded-tr-lg rounded-tl-lg">
      <div class="left">
        {{ title }}
      </div>
      <div v-if="canUpdate()" class="right flex gap-1">
        <button v-if="cardsImporter && cardsImportModel" @click="downLoadImportModel" class="h-8 px-3 py-2 text-xs text-white transition-colors duration-150 bg-green-500 rounded-lg focus:shadow-outline hover:bg-green-700"><f-icon icon="download" /> {{ $t('actions.download_import_model') }}</button>
        <button v-if="cardsImporter" @click="openImportModal" class="h-8 px-3 py-2 text-xs text-white transition-colors duration-150 bg-green-500 rounded-lg focus:shadow-outline hover:bg-green-700"><f-icon icon="upload" /> {{ $t('actions.import_cards') }}</button>
        <button v-if="mediaImporter" @click="openImportMediaModal" class="h-8 px-3 py-2 text-xs text-white transition-colors duration-150 bg-green-500 rounded-lg focus:shadow-outline hover:bg-green-700"><f-icon icon="upload" /> {{ $t('actions.import_media') }}</button>
        <button v-if="shapefileImporter" @click="openImportShapefileModal" class="h-8 px-3 py-2 text-xs text-white transition-colors duration-150 bg-green-500 rounded-lg focus:shadow-outline hover:bg-green-700"><f-icon icon="vector-square" /> {{ $t('actions.import_shapefile') }}</button>
        <button v-if="cardsExporter" @click="openExportModal" class="h-8 px-3 py-2 text-xs text-white transition-colors duration-150 bg-green-500 rounded-lg focus:shadow-outline hover:bg-green-700"><f-icon icon="file-export" /> {{ $t('actions.export_cards') }}</button>
        <router-link v-if="!notAdd" :to="`/${model}/new`" class="h-8 px-3 py-2 text-xs text-white transition-colors duration-150 bg-green-500 rounded-lg focus:shadow-outline hover:bg-green-700"><f-icon icon="plus" /> {{ $t('actions.new') }}</router-link>
      </div>
    </div>
    <div class="box-content p-4 bg-slate-200 border-t border-b border-slate-300">
        <form @submit.prevent="filterSearch(model, search)" v-if="searchable" class="w-full">
          <div v-if="formFilters" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6">
            <div v-for="(filter, index) in formFilters" :key="'filter-'+index" class="mr-2">
              <input v-if="filter.type === 'text'" type="text" v-model.lazy="search[filter.field]" :placeholder="filter.label" class="h-8 px-3 py-2 text-xs bg-gray-100 rounded-lg outline-none mb-1 w-full" />
              <VSelect v-if="filter.type === 'select'"
                v-model.lazy="search[filter.field]"
                :model="filter.model"
                :search-field="filter.search_field"
                :label="filter.search_field"
                :labels="filter.labels"
                value-field="id"
                :placeholder="filter.placeholder || $t('global.select')"
                class="block py-1 px-1 h-8 bg-white rounded-lg focus:outline-none mb-1 text-xs"
                ></VSelect>
              <vue-select v-if="filter.type === 'static-select'"
                v-model.lazy="search[filter.field]" 
                :options="filter.options"
                :label="filter.label"
                :placeholder="filter.placeholder"
                :taggable="false"
                :ref="`select-${search[filter.field]}`"
                class="block py-1 px-1 h-8 bg-white rounded-lg focus:outline-none mb-1 text-xs"
                >
                  <span slot="no-options">{{ $t('global.no_results') }}</span>
              </vue-select>
            </div>
            <button type="submit" class="h-8 px-1 py-1 mr-2 bg-green-500 rounded-lg outline-none text-white">
              <span class="pr-1 text-sm">{{ $t('actions.search') }}</span>
              <f-icon class="text-sm pt-1" icon="magnifying-glass" />
            </button>
          </div>
        </form>
    </div>
    
    <div v-if="selected.length > 0 && isAdmin()" class="box-content p-4 bg-green-500 border-t-2 border-b-2 border-gray-400 flex justify-between">
      <div class="text-white">{{ $t('actions.group') }}</div>
      <button @click="destroyPrompt(selected, true)" class="border-4 border-opacity-0 border-red-500 bg-red-500 py-1 px-2 font-bold uppercase text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:text-red-500"><f-icon icon="trash-can" /></button>
    </div>

    <table class="min-w-full divide-y divide-gray-200">
       <thead v-if="!grid" class="bg-gray-50 sticky top-20 z-10">
          <tr>
            <th v-if="isAdmin()" scope="col" class="px-3 py-3 text-left text-gray-600 uppercase tracking-wider">
              <span><input type="checkbox" v-model="toggle" /></span>
            </th>
            <th scope="col" class="px-3 py-3 text-left text-gray-600 uppercase tracking-wider" v-for="(column, index) in columns" :key="index">
              <span v-if="column.field === 'actions'">{{ $t('actions.label') }}</span>
              <span v-else>
                <a v-if="column.sortable" href="#" @click.prevent="sort(column.related ? `${column.related}s.${column.field}` : column.field)">
                  <f-icon v-if="column.icon" :icon="column.icon" />
                  <span v-else>{{ column.name }}</span>
                  <f-icon class="ml-1" icon="sort" />
                </a>
                <span v-else>
                  <f-icon v-if="column.icon" :icon="column.icon" />
                  <span v-else>{{ column.name }}</span>
                </span>
              </span>
            </th>
          </tr>
       </thead>
       <thead v-else>
        <tr>
            <th v-if="isAdmin()" scope="col" class="px-3 py-3 text-left text-gray-600 uppercase tracking-wider">
              <span><input type="checkbox" v-model="toggle" /></span>
            </th>
            </tr>
       </thead>
       <tbody :class="'bg-white divide-y divide-gray-200' + (grid ? ' grid grid-cols-4 gap-3 p-3' : '')">
         <tr v-for="(entity, index) in entities" :key="index" :class="grid ? 'flex flex-col border col-span-1 justify-between  rounded bg-white' : ''">
           <td v-if="isAdmin()" :class="grid ? 'px-3 py-1 ' : 'px-3 py-4 ' + 'whitespace-nowrap'">
              <input type="checkbox" v-model="entity.checked" />
           </td> 
           <td :class="grid ? 'p-0 flex flex-col items-center ' : 'px-3 py-4 ' + 'whitespace-nowrap'" v-for="(column, index) in columns" :key="index">
              <!-- field "actions" -->
              <div v-if="column.field === 'actions'" :class="grid ? 'py-3' : ''">
                <div v-if="entity.read_only">
                  <router-link :to="`/${model}/show/${entity.id}`" :class="'py-2 px-3 border-4 border-opacity-0 border-green-600 bg-green-600 font-bold uppercase text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:text-green-600'"><f-icon icon="eye" /></router-link>
                </div>
                <div v-else class="flex gap-1">
                  <router-link v-if="canUpdate(entity)" :to="`/${model}/edit/${entity.id}`" :class="'py-2 px-3 border-4 border-opacity-0 font-bold uppercase text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 border-blue-500 bg-blue-500 hover:text-blue-500'">
                    <f-icon :icon="'pen-to-square'" />
                  </router-link>
                  <router-link v-else :to="`/${model}/show/${entity.id}`" :class="'py-2 px-3 border-4 border-opacity-0 font-bold uppercase text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 border-green-500 bg-green-500 hover:text-green-500'">
                    <f-icon :icon="'eye'" />
                  </router-link>
                  <button v-if="(canClone() && !cantClone) || (isAdmin() && !cantClone)" @click="clone(entity)" :class="'py-2 px-3 border-4 border-opacity-0 border-purple-500 bg-purple-500 font-bold uppercase text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:text-purple-500'"><f-icon icon="copy" /></button>
                  <button v-if="(canDelete() && !cantDelete) || isAdmin()" @click="destroyPrompt(entity)" :class="'py-2 px-3 border-4 border-opacity-0 border-red-500 bg-red-500 font-bold uppercase text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:text-red-500'"><f-icon icon="trash-can" /></button>
                </div>
              </div>
              <!-- other fields -->
              <span v-else :class="grid ? 'p-2' : ''">
                <!-- if is related -->
                <span v-if="column.related">
                  <CrudTableField v-if="entity[column.related]" :column="column" :entity="entity[column.related]" />
                </span>
                <!-- if not related -->
                <span v-else>
                  <CrudTableField v-if="entity" :column="column" :entity="entity" />
                </span>
              </span><!-- end other fields -->
           </td>
         </tr>
       </tbody>
    </table>

    <nav v-if="pagination" aria-label="Page navigation" class="pagination flex justify-center mt-10 pb-10 outline-none">
      <div class="">
        <ul class="inline-flex outline-none">
          <li v-if="pagination.current_page > 1">
            <button @click="itemClick(pagination.current_page - 1)" :class="base + element + inactive + 'rounded-l-lg'">{{ $t('pagination.prev') }}</button>
          </li>
          <li v-if="pagination.current_page > 2">
            <button @click="itemClick(1)" :class="base + element + inactive">1</button>
          </li>
          <li v-if="pagination.current_page > 3">
            <button @click="itemClick(2)" :class="base + element + inactive">2</button>
          </li>
          <li v-if="pagination.current_page > 4">
            <button disabled :class="base + element + inactive">…</button>
          </li>
          <li v-if="(pagination.current_page - 1) > 0">
            <button @click="itemClick(pagination.current_page - 1)" :class="base + element + inactive">{{pagination.current_page - 1}}</button>
          </li>
          <li v-if="pagination.current_page && pagination.current_page != 1 && pagination.current_page != pagination.last_page">
            <button disabled :class="base + element + active">{{pagination.current_page}}</button>
          </li>
          <li v-if="pagination.current_page && pagination.current_page == 1">
            <button disabled  :class="base + element + active + 'rounded-l-lg'">{{pagination.current_page}}</button>
          </li>
          <li v-if="pagination.current_page == pagination.last_page">
            <button disabled :class="base + element + active + 'rounded-r-lg'">{{pagination.current_page}}</button>
          </li>
          <li v-if="(pagination.current_page + 1) <= pagination.last_page">
            <button @click="itemClick(pagination.current_page + 1)" :class="base + element + inactive">{{pagination.current_page + 1}}</button>
          </li>
          <li v-if="(pagination.current_page + 2) <= pagination.last_page">
            <button @click="itemClick(pagination.current_page + 2)" :class="base + element + inactive">{{pagination.current_page + 2}}</button>
          </li>
          <li v-if="(pagination.last_page - pagination.current_page) > 10">
            <button disabled :class="base + element + inactive">…</button>
          </li>
          <li v-if="(pagination.last_page - pagination.current_page) > 0">
            <button @click="itemClick(pagination.current_page + 1)" :class="base + inactive + last + 'rounded-r-lg'">{{ $t('pagination.next') }}</button>
          </li>
          <li v-if="(pagination.last_page - pagination.current_page) > 2">
            <button @click="itemClick(pagination.last_page)" :class="base + last + inactive + 'ml-2 rounded-lg'">{{ $t('pagination.last') }}</button>
          </li>
        </ul>
        <div class="flex justify-center mt-4 text-xs items-center">
          <select v-model="pagination.per_page" class="block py-1 px-1 text-xs mr-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" @change="changePerPage">
            <option disabled value="">{{ $t('global.select') }}</option>
            <option v-for="(option, index) in perPageOptions" :key="`per-page-option-${index}`" :value="option">
              {{ option }}
            </option>
          </select>
          <span class="">{{ $t('pagination.results')}} {{(pagination.from || 0) + ' - ' + (pagination.to || 0)}} {{ $t('global.of') }} {{pagination.total}}</span>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import Api from '@/components/Api'
import CrudTableField from '@/components/CrudTableField'
import ModalConfirm from '@/components/ModalConfirm'
import ModalDelete from '@/components/ModalDelete'
import ModalImporter from '@/components/ModalImporter'
import ModalMediaImporter from '@/components/ModalMediaImporter'
import ModalExporter from '@/components/ModalExporter'
import ModalShapefileImporter from '@/components/ModalShapefileImporter'

export default {
  name: 'CrudTable',
  mixins: [
    Api
  ],
  components: {
    CrudTableField
  },
  props: {
    title: {
      type: String,
      default: 'Default Title',
    },
    model: {
      type: String,
      default: null
    },
    columns: {
      type: Array,
      default: null
    },
    modelNameField: {
      type: String,
      default: 'name'
    },
    searchable: {
      type: Boolean,
      default: true
    },
    cardsImporter: {
      type: Boolean,
      default: false
    },
    cardsImporterArchiveText: {
      type: String
    },
    cardsImportModel: {
      type: String,
      default: null
    },
    cardsFileTypesAllowed: {
      type: Array
    },
    cardsExporter: {
      type: Boolean,
      default: false
    },
    cardsExporterText: {
      type: String
    },
    mediaImporter: {
      type: Boolean,
      default: false
    },
    mediaImporterText: {
      type: String
    },
    mediaFileTypesAllowed: {
      type: Array
    },
    searchFilters: {
      type: Array,
      default: () => []
    },
    checkOwner: {
      type: Boolean,
      default: false
    },
    extraParams: {
      type: Object,
      default: () => {}
    },
    grid: {
      type: Boolean,
      default: false
    },
    perPageOptions: {
      type: Array,
      default: () => ([10, 20, 50, 100])
    },
    notAdd: {
      type: Boolean,
      default: false
    },
    shapefileImporter: {
      type: Boolean,
      default: false
    },
    shapefileImporterArchiveText: {
      type: String
    },
    cantDelete: {
      type: Boolean,
      default: false
    },
    importHasArchive: {
      type: Boolean,
      default: true
    },
    cantEdit: {
      type: Boolean,
      default: false
    },
    cantClone: {
      type: Boolean,
      default: false
    },
    checkRoles: {
      type: Array,
      default: () => []
    },
  },
  data: function(){
    return {
      toggle: false,
      selected: []
    }
  },
  watch: {
    toggle(value) {
      const vm = this
      vm.entities.forEach(entity => { 
        //entity.checked = value 
        vm.$set(entity, 'checked', value)
      });
      this.updateSelection()
    },
    entities: {
      deep: true, // This will let Vue know to look inside the array
      handler() { // We have to move our method to a handler field (can use value as param)
        this.updateSelection()
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user'
    }),
    base() {
      return 'h-8 px-3 transition-colors duration-150 border border-green-600 outline-none ';
    },
    inactive() {
      return 'text-green-600 bg-white hover:bg-green-100 ';
    },
    element() {
      return 'border-r-0 ';
    },
    last() {
      return 'border-r-1 ';
    },
    active() {
      return 'text-white bg-green-600 ';
    },
    formFilters() {
      return [...this.searchFilters, { type: 'text', field: 'full_text', label: this.$t('global.search_everywhere')}]
    }
  },
  mounted(){
    this.fetch(this.model, null, 1, 20, this.extraParams)
  },
  methods: {
    show(id){
      this.$router.push(`${this.model}/${id}`)
    },
    edit(id){
      this.$router(`${this.model}/edit/${id}`)
    },
    updateSelection(){
      this.selected = this.entities.filter(function(entity){ return entity.checked; });
    },
    async destroy(entity){
      this.entities.splice(this.entities.indexOf(entity), 1);
      this.$modal.hide()
      let destroyed = await this.delete(this.model, entity.id)
      return !!destroyed
    },
    async destroyPrompt(entity, multiple = false){
      this.$modal.show(ModalDelete,
        {
          title: this.$i18n.t('modals.delete.title'),
          text: this.$i18n.t('modals.delete.content'),
          deleteText: this.$i18n.t('actions.delete'),
          entity: entity,
          multiple: multiple,
          model: this.model,
          update: (deleted) => {
            if(deleted === 'reload'){ // reload data in case of multiple deletion
              this.fetch(this.model, null, 1, 20, this.extraParams) 
              this.toggle = false 
            } 
            this.entities.splice(this.entities.indexOf(deleted), 1);
          }
        },{
          height: 'auto'
        }
      )
    },
    itemClick(val, val2 = null) {
      this.fetch(this.model, null, val, val2 || this.pagination.per_page, this.extraParams)
    },
    changePerPage(e){
      this.$set(this.pagination, 'per_page', e.target.value)
      this.fetch(this.model, null, this.pagination.current_page, this.pagination.per_page, this.extraParams)
    },
    openImportModal(){
      this.$modal.show(ModalImporter,
        {
          title: this.$i18n.t('modals.importer.title', {name: this.title}),
          archiveText: this.cardsImporterArchiveText,
          text: this.$i18n.t('modals.importer.content'),
          importText: this.$i18n.t('actions.import'),
          cancelText: this.$i18n.t('actions.cancel'),
          fileTypesAllowed: this.cardsFileTypesAllowed,
          hasArchive: this.importHasArchive,
          model: this.model,
          update: (imported) => {
            console.log(imported)
          }
        },{
          height: 'auto'
        }
      )
    },
    openImportMediaModal(){
      this.$modal.show(ModalMediaImporter,
        {
          title: this.$i18n.t('modals.importer.media_title', {name: this.title}),
          archiveText: this.mediaImporterText,
          text: this.$i18n.t('modals.importer.media_content'),
          importText: this.$i18n.t('actions.import'),
          cancelText: this.$i18n.t('actions.cancel'),
          fileTypesAllowed: this.mediaFileTypesAllowed,
          model: this.model,
          update: (imported) => {
            console.log(imported)
          }
        },{
          height: 'auto'
        }
      )
    },
    openExportModal(){
      this.$modal.show(ModalExporter,
        {
          title: this.$i18n.t('modals.exporter.title', {name: this.title}),
          text: this.$i18n.t('modals.exporter.content'),
          exportText: this.$i18n.t('actions.export'),
          cancelText: this.$i18n.t('actions.cancel'),
          model: this.model,
          filters: this.search,
          update: (exported) => {
            console.log(exported)
          }
        },{
          height: 'auto'
        }
      )
    },
    isMyCard(entity){
      if(!this.checkOwner) return true
      let archives = this.user.archives ? this.user.archives.filter(function(archive){
        return entity.card ? entity.card.archive_id === archive.id : entity.archive_id === archive.id
      }) : null
      let registries = this.user.registries ? this.user.registries.filter(function(registry){
        return entity.card ? entity.card.registry_id === registry.id : entity.registry_id === registry.id
      }) : null
      return (archives && archives.length > 0 || registries && registries.length > 0)
    },
    isAdmin(){
      return this.user.user_roles.includes('admin')
    },
    canUpdate(entity = null){
      const vm = this
      // if can't edit return always false
      if(vm.cantEdit) return false
      let checkRole = false
      // check if user has at least 1 role to proceed other checks
      if(vm.user.user_roles && vm.user.user_roles.length > 0) {
        // if from views came at least 1 role check
        if(vm.checkRoles) vm.checkRoles.forEach(role => {
          if(!checkRole) { // if the check is yet false
            //console.log('[check role]', role, vm.user.user_roles.includes(role), (entity && entity.card ? entity.card.user_id === vm.user.id : entity && entity.user_id === vm.user.id))
            // if role is Card Manager must check user association with card
            if(role === 'card_manager') checkRole = vm.user.user_roles.includes(role) && (entity && entity.card ? entity.card.user_id === vm.user.id : entity && entity.user_id === vm.user.id)
            // if role is Person Compiler must check user association with person
            if(role === 'person_compiler') checkRole = vm.user.user_roles.includes(role) && (entity && entity.slug === 'persons' ? entity.user_id === vm.user.id : entity && entity.user_id === vm.user.id)
            // else there is a simple role check
            else checkRole = vm.user.user_roles.includes(role)
          }
        })
        // if admin always can anything
        if(vm.user.user_roles.includes('admin')) checkRole = true
      }
      return checkRole
    },
    canClone(){
      if(this.cantClone) return false
      return this.user.user_roles && this.user.user_roles.length > 0 && (this.user.user_roles.includes('admin') || this.user.user_roles.includes('archive_manager'))
    },
    canDelete(){
      if(this.cantDelete) return false
      return this.user.user_roles && this.user.user_roles.length > 0 && (this.user.user_roles.includes('admin') || this.user.user_roles.includes('archive_manager'))
    },
    downLoadImportModel(){
      const link = document.createElement('a');
      link.href = process.env.VUE_APP_BASE_PATH+'resources/'+this.cardsImportModel  // 
      link.setAttribute('download', this.cardsImportModel)
      console.log(link)
      document.body.appendChild(link);
      link.click();
    },
    openImportShapefileModal(){
      this.$modal.show(ModalShapefileImporter,
        {
          title: this.$i18n.t('modals.importer.shapefile_title', {name: this.title}),
          archiveText: this.shapefileImporterText,
          text: this.$i18n.t('modals.importer.shapefile_content'),
          importText: this.$i18n.t('actions.import'),
          cancelText: this.$i18n.t('actions.cancel'),
          model: this.model,
          update: (imported) => {
            console.log(imported)
          }
        },{
          height: 'auto'
        }
      )
    },
    clone(entity){
      const vm = this
      vm.$modal.show(ModalConfirm,
        {
          title: vm.$i18n.t('modals.clone.title'),
          text: vm.$i18n.t('modals.clone.content'),
          confirmText: vm.$i18n.t('actions.confirm'),
          update: async (confirm) => {
            if(confirm){
              let card_id = entity.card ? entity.card.id : entity.id
              await vm.post(`${entity.card ? 'cards' : vm.model}/clone/${card_id}`).then((response) => {
                if(response.success){
                  vm.$toasted.success(response.message || vm.$i18n.t('modals.clone.confirmed'))
                  vm.fetch(vm.model, null, 1, 20, vm.extraParams) 
                }
                vm.$toasted.error(response.errors)
              })
            }
          }
        },{
          height: 'auto'
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
