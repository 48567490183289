// === ReSole ereditaculturali Plugin === //

import store from '@/store'

// Import Views
import FCards from './views/FCards'
import FCard from './views/FCard'
import Collections from './views/Collections'
import Collection from './views/Collection'
import ScanCards from './views/ScanCards'
import ScanCard from './views/ScanCard'
import ProjectWebGIS from './views/ProjectWebGIS'
import Wiki from './views/Wiki'
import Place from './views/Place'
import Places from './views/Places'
import Placename from './views/Placename'
import Placenames from './views/Placenames'
import PlacenameType from './views/PlacenameType'
import PlacenameTypes from './views/PlacenameTypes'

const ereditaculturali = {
    name: 'ereditaculturali',
    routes: [
      // FCards
      {
        path: '/f_cards',
        name: 'f_cards',
        component: FCards,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/f_cards/edit/:id',
        name: 'f_card.edit',
        component: FCard,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/f_cards/show/:id',
        name: 'f_card.show',
        component: FCard,
        props: { mode: 'show', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/f_cards/new',
        name: 'f_card.new',
        component: FCard,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // Collections
      {
        path: '/collections',
        name: 'collections',
        component: Collections,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/collections/edit/:id',
        name: 'collection.edit',
        component: Collection,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/collections/show/:id',
        name: 'collection.show',
        component: Collection,
        props: { mode: 'show', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/collections/new',
        name: 'collection.new',
        component: Collection,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // ScanCards
      {
        path: '/scan_cards',
        name: 'scan_cards',
        component: ScanCards,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/scan_cards/edit/:id',
        name: 'scan_card.edit',
        component: ScanCard,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/scan_cards/show/:id',
        name: 'scan_card.show',
        component: ScanCard,
        props: { mode: 'show', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/scan_cards/new',
        name: 'scan_card.new',
        component: ScanCard,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/webgis',
        name: 'webgis',
        component: ProjectWebGIS,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/wiki',
        name: 'wiki',
        component: Wiki,
        props: { mode: 'show', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // PlacenameType routes
      {
        path: '/placename_types',
        name: 'placename_types',
        component: PlacenameTypes,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(user.permissions.includes('manage_users')){
            next()
          }else{
            next({name: 'home'})
          }
        }
      },
      {
        path: '/placename_types/edit/:id',
        name: 'placename_type.edit',
        component: PlacenameType,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(user.permissions.includes('manage_users')){
            next()
          }else{
            next({name: 'home'})
          }
        }
      },
      {
        path: '/placename_types/new',
        name: 'placename_type.new',
        component: PlacenameType,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(user.permissions.includes('manage_users')){
            next()
          }else{
            next({name: 'home'})
          }
        }
      }, 
      // Places
      {
        path: '/places',
        name: 'places',
        component: Places,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/places/edit/:id',
        name: 'place.edit',
        component: Place,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/places/show/:id',
        name: 'place.show',
        component: Place,
        props: { mode: 'show', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/places/new',
        name: 'place.new',
        component: Place,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // Placenames
      {
        path: '/placenames',
        name: 'placenames',
        component: Placenames,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/placenames/edit/:id',
        name: 'placename.edit',
        component: Placename,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/placenames/show/:id',
        name: 'placename.show',
        component: Placename,
        props: { mode: 'show', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/placenames/new',
        name: 'placename.new',
        component: Placename,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
    ],
    menu: {
      cards: [
        {route: 'separator',            permissions: ['manage_archives', 'manage_cards'], label: 'plugins.ereditaculturali.menu.cards', items: []},
        {route: '/scan_cards',          permissions: ['manage_archives', 'manage_cards'],          label: 'plugins.ereditaculturali.menu.scan_cards', icon: 'building-columns'},
        {route: '/f_cards',             permissions: ['manage_archives', 'manage_cards'],          label: 'plugins.ereditaculturali.menu.f_cards', icon: 'image'},
        {route: '/places',              permissions: ['all'],          label: 'plugins.ereditaculturali.menu.places', icon: 'map-location-dot'},
        {route: '/placenames',          permissions: ['all'],          label: 'plugins.ereditaculturali.menu.placenames', icon: 'signs-post'},
        {route: '/webgis',              permissions: ['all'],          label: 'plugins.ereditaculturali.menu.webgis', icon: 'compass'},
        {route: '/wiki',                permissions: ['manage_cards'], label: 'plugins.ereditaculturali.menu.wiki', icon: 'book'},
      ],
      taxonomies: [
        {route: 'separator',            permissions: ['manage_archives', 'manage_cards'], label: 'plugins.ereditaculturali.menu.taxonomies', items: []},
        {route: '/collections',         permissions: ['manage_archives'], label: 'plugins.ereditaculturali.menu.collections', icon: 'images'},
        {route: '/placename_types',     permissions: ['manage_users'], label: 'plugins.ereditaculturali.menu.placename_types', icon: 'magnifying-glass-location'},
      ]
    },
    // menu: [
    //   {route: '/f_cards',             permissions: ['manage_archives', 'manage_cards'],          label: 'plugins.ereditaculturali.menu.f_cards', icon: 'image'},
    //   {route: '/collections',         permissions: ['manage_archives'], label: 'plugins.ereditaculturali.menu.collections', icon: 'images'},
    //   {route: '/scan_cards',          permissions: ['manage_archives', 'manage_cards'],          label: 'plugins.ereditaculturali.menu.scan_cards', icon: 'building-columns'},
    //   {route: '/webgis',              permissions: ['all'],          label: 'plugins.ereditaculturali.menu.webgis', icon: 'compass'},
    //   {route: '/places',              permissions: ['all'],          label: 'plugins.ereditaculturali.menu.places', icon: 'map-location-dot'},
    //   {route: '/placenames',          permissions: ['all'],          label: 'plugins.ereditaculturali.menu.placenames', icon: 'signs-post'},
    //   {route: '/placename_types',     permissions: ['manage_users'], label: 'plugins.ereditaculturali.menu.placename_types', icon: 'magnifying-glass-location'},
    //   {route: '/wiki',                permissions: ['manage_cards'], label: 'plugins.ereditaculturali.menu.wiki', icon: 'book'},
    //   {route: 'separator',            permissions: ['all']}
    // ],
    models: ['f_cards', 'scan_cards'],
    dashboard: {
      stats: 'ereditaculturali',
      charts: [
        {id: 'chart-f_cards', title: 'Schede F', model: 'f_cards', stats: 'f_cards', type: 'doughnut', },
        {id: 'chart-scan_cards', title: 'Schede SCAN', model: 'scan_cards', stats: 'scan_cards', type: 'doughnut'},
        {id: 'chart-places', title: 'Toponimi', model: 'places', stats: 'places', type: 'doughnut'},
        {id: 'chart-placenames', title: 'Toponimi storici', model: 'placenames', stats: 'placenames', type: 'doughnut'},
      ]
    },
    messages: {
      it: {
        menu: {
          cards: 'Schede',
          f_cards: 'Schede F',
          collections: 'Collezioni',
          scan_cards: 'Schede SCAN',
          lemmas: 'Lemmi',
          webgis: 'WebGIS',
          wiki: 'Wiki',
          places: 'Toponimi',
          placename_types: 'Tipologie Toponimi',
          placenames: 'Toponimi storici',
          taxonomies: 'Eredità Culturali'
        },
        tips: {
          is_visible: 'Se spuntato rende visibile la scheda a tutti gli utenti dell\'archivio',
          is_published: 'Se spuntato rende visibile la scheda al portale web',
        },
        views: {
          f_cards: {
            title: 'Schede fotografiche ICCD',
            subtitle: 'Schede F',
            single: 'Scheda F',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
              card: 'Scheda',
              archive: 'Archivio',
              f_card_separator: 'Dati specifici Scheda F',
              inventario: 'Inventario',
              archivio: 'Archivio',
              fondo_collezione: 'Collezione',
              ente_produttore: 'Ente produttore',
              foglio_IGM: 'Foglio IGM',
              strisciata: 'Strisciata',
              numero_inventario_patrimoniale: 'Numero inventario patrimoniale',
              collocazione: 'Collocazione',
              codice_univoco: 'Codice univoco',
              definizione_oggetto: 'Definizione oggetto',
              unita_archivistica: 'Unità archivistica',
              indicazione_colore: 'Indicazione colore',
              materia_tecnica_oggetto: 'Materia e tecnica oggetto',
              misure_formato: 'Misure e formato',
              cronologia_generica: 'Cronologia generica',
              cronologia_specifica: 'Cronologia specifica',
              autore_fotografia: 'Autore fotografia',
              editore: 'Editore',
              categorie: 'Categorie',
              soggetto: 'Soggetto',
              titolo: 'Titolo',
              luogo_ripresa: 'Luogo ripresa',
              data_ripresa: 'Data ripresa',
              cronologia_opera_fotografata: 'Cronologia opera fotografata',
              autore_opera_fotografata: 'Autore opera fotografata',
              iscrizioni_timbri: 'Iscrizioni timbri',
              alterazioni_deterioramenti: 'Alterazioni deterioramenti',
              data_restauro: 'Data restauro',
              bibliografia_specifica: 'Bibliografia specifica',
              mostre: 'Mostre',
              numero_riproduzione: 'Numero riproduzione',
              numero_fotografia_originale: 'Numero fotografia originale',
              collocazione_riproduzione_stampa: 'Collocazione riproduzione stampa',
              compilatore_scheda: 'Compilatore scheda',
              funzionario_responsabile: 'Funzionario responsabile',
              note_lavoro: 'Note lavoro',
              collection: 'Collezione',
              cm_separator: 'Certificazione / Gestione dati',
              rv_separator: 'Relazioni tra schede',
              type: 'Tipo',
              direction: 'Verso della relazione',
              notes: 'Note',
              codice_luogo: 'Codice luogo',
              image: ''
            },
            importer_archive_text: 'Seleziona un fondo da associare alle schede',
            importer_media_text: 'Seleziona un fondo da associare',
            uploader_text: 'Carica documentazione alla scheda',
            location: {
              title: 'Dati GIS associati'
            },
            f_certifications: {
              title: 'Voci di certificazione / gestione dati',
              add: 'Aggiungi un nuova voce di certificazione/gestione dati',
              fields: {
                cmr: 'Responsabile',
                cma: 'Anno di redazione',
                adp: 'Profilo di pubblicazione',
                oss: 'Note'
              }
            },
            related_card: {
              name: 'Gestisci le relazioni tra Schede F',
              title: 'Relazioni esistenti con altre Schede F',
              add_new: 'Aggiungi un nuova relazione',
              tip: `Creando una relazione da questa scheda, la scheda associata diventa "figlia" dell'attuale`,
              fields: {}
            }
          },
          collections: {
            title: 'Collezioni e Fondi per le Schede F ICCD',
            subtitle: 'Collezioni Schede F',
            single: 'Collezione',
            fields: {
              code: 'Codice',
              name: 'Nome',
              bibliography: 'Bibliografia',
              fcards_count: 'Schede F associate',
              image: '',
            },
            select: 'Seleziona una collezione'
          },
          scan_cards: {
            title: 'Schede SCAN ICCD',
            subtitle: 'Schede SCAN',
            single: 'Scheda SCAN',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
              card: 'Scheda',
              archive: 'Fondo',
              persons: 'Persone',
              image: '',
              tsk: 'Tipo scheda (TSK)',
              lir: 'Livello catalogazione (LIR)',
              nctr: 'Codice regione (NCTR)',
              nctn: 'Numero catalogo generale (NTCN)',
              esc: 'Ente schedatore (ESC)',
              ecp: 'Ente competente per tutela (ECP)',
              acce: 'Ente/soggetto responsabile (ACCE)',
              accw: 'Indirizzo web (ACCW)',
              ctb: 'Categoria generale (CTB)',
              set: 'Settore disciplinare (SET)',
              tbc: 'Tipo bene culturale (TBC)',
              ctg: 'Categoria disciplinare (CTG)',
              ogd: 'Definizione bene (OGD)',
              ogt: 'Tipologia/altre specifiche (OGT)',
              ogn: 'Denominazione/titolo (OGN)',
              rser: 'Tipo di relazione (RSER)',
              rses: 'Specifiche tipo relazione (RSES)',
              rez: 'Note sulle relazioni (REZ)',
              lcs: 'Stato (LCS)',
              lcr: 'Regione (LCR)',
              lcp: 'Provincia (LCP)',
              lcc: 'Comune (LCC)',
              lci: 'Indirizzo (LCI)',
              lcv: 'Altri percorsi/specifiche (LCV)',
              plv: 'Toponimo / Località (PLV)',
              pvz: 'Tipo di contesto (PVZ)',
              gel: 'Tipo di localizzazione (GEL)',
              get: 'Tipo di georeferenziazione (GET)',
              gep: 'Sistema di riferimento (GEP)',
              gec: 'Coordinate (GEC)',
              gecx: 'Coordinata x (GECX)',
              gecy: 'Coordinata y (GECY)',
              aff: 'Affidabilità (AFF)',
              gpbb: 'Descrizione sintetica (GPBB)',
              gpbt: 'Data (GPBT)',
              gpbu: 'Indirizzo web (GPBU)',
              dtzg: 'Fascia cronologica/periodo (DTZG)',
              dtzs: 'Specifiche (DTZS)',
              dtsi: 'Da (DTSI)',
              dtsv: 'Validità Da (DTSV)',
              dtsf: 'A (DTSF)',
              dtsl: 'Validità A (DTSL)',
              dtt: 'Note (DTT)',
              des: 'Descrizione del bene (DES)',
              aid: 'Apparato iconografico/decorativo (AID)',
              cmr: 'Responsabile (CMR)',
              cma: 'Anno di redazione (CMA)',
              adp: 'Profilo di pubblicazione (ADP)',
              cam: 'Caratteri ambientali beni immobili (CAM)',
              cd_separator: 'Identificazione (CD)',
              og_separator: 'Bene culturale (OG)',
              rv_separator: 'Relazioni (RV)',
              lc_separator: 'Localizzazione (LC)',
              ge_separator: 'Georeferenziazione (GE)',
              dt_separator: 'Cronologia / Definizione Culturale (DT)',
              da_separator: 'Dati analitici (DA)',
              do_separator: 'Documentazione (DO)',
              cm_separator: 'Certificazione / Gestione dati (CM)',
              other_fields: 'Altri campi',
              atb: 'Ambito culturale (ATB)',
              res: 'Dati provenienza archeologica (RES)',
              nsc: 'Approfondimenti storico-critici (NSC)',
              mt_separator: 'Dati tecnici (MT)',
              nvc: 'Provvedimenti amministrativi-specifiche (NVC)',
              notes: 'Altre note',
              ro: 'Risorse Online',
              accr: 'Riferimento cronologico (ACCR)',
              accs: 'Note (ACCS)',
              ldct: 'Tipologia contenitore fisico (LDCT)',
              ldcn: 'Denominazione contenitore fisico (LDCN)',
              ldcm: 'Denominazione contenitore giuridico (LDCM)',
              ldcz: 'Collezione (LDCZ)',
              ldcs: 'Specifiche di collocazione (LDCS)',
              elr: 'Iscrizioni/elementi di rilievo (ELR)',
              mtc: 'Materia/tecnica-materiale composito (MTC)',
              cdg: 'Condizione giuridica (CDG)',
              stc: 'Stato di conservazione (STC)',
              rst: 'Restauri e altri interventi (RST)',
              ldck: 'Codice contenitore fisico (LDCK)',
              ldcg: 'Codice contenitore giuridico (LDCG)',
              sta: 'Situazione (STA)',
              pve: 'Diocesi (PVE)',
            },
            importer_archive_text: 'Seleziona un fondo da associare alle schede',
            uploader_text: 'Carica documentazione alla scheda',
            location: {
              title: 'Dati GIS associati'
            },
            scan_documentations: {
              title: 'Documentazione associata alla scheda',
              add: 'Aggiungi un nuovo elemento della documentazione SCAN (DO)',
              fields: {
                dcmn: 'Codice identificativo (DCMN)',
                dcmp: 'Tipo (DCMP)',
                dcmm: 'Titolo/didascalia (DCMM)',
                dcma: 'Autore (DCMA)',
                dcmr: 'Riferimento cronologico (DCMR)',
                dcme: 'Ente proprietario (DCME)',
                dcmc: 'Collocazione (DCMC)',
                dcmw: 'Indirizzo web (DCMW)',
                dcmk: 'Nome file (DCMK)',
                dcmt: 'Note (DCMT)',
                bib: 'Bibliografia (BIB)',
                files: 'File associati'
              }
            },
            scan_historicals: {
              title: 'Notizie storiche associate alla scheda',
              add: 'Aggiungi un nuova notizia storica',
              fields: {
                dtns: 'Notizia di sintesi (DTNS)',
                dtnn: 'Notizia di dettaglio (DTNN)',
                dtr: 'Riferimento cronologico (DTR)',
                dtg: 'Specifiche riferimento cronologico (DTG)'
              }
            },
            scan_certifications: {
              title: 'Voci di certificazione / gestione dati (CM)',
              add: 'Aggiungi un nuova voce di certificazione/gestione dati',
              fields: {
                cmr: 'Responsabile (CMR)',
                cma: 'Anno di redazione (CMA)',
                adp: 'Profilo di pubblicazione (ADP)',
                oss: 'Note (OSS)',
                cmm:  'Committenza (CMM)',
                cmmn: `Nome della persona o dell'istituzione committente nella forma "cognome, nome, titolo" (CMMN)`,
                cmmd: 'Data (CMMD)',
                cmmc: `Occasione in cui è stata commissionata l'opera (CMMC)`,
                cmmf: 'Fonte (CMMF)',
              }
            },
            scan_technical_datas: {
              title: 'Misure Dati Tecnici (MT / MIS)',
              add: 'Aggiungi un nuova voce di misure',
              fields: {
                misz: 'Tipo di misura (MISZ)',
                misu: 'Unità di misura (MISU)',
                mism: 'Valore (MISM)',
                misv: 'Note (MISV)'
              }
            },
            related_card: {
              name: `Gestisci le relazioni tra Schede SCAN`,
              title: 'Relazioni esistenti con altre Schede SCAN',
              add_new: 'Aggiungi un nuova relazione',
              tip: `Creando una relazione da questa scheda, la scheda associata diventa "figlia" dell'attuale`,
              fields: {}
            },
            scan_authorities: {
              title: 'Autore responsabilità (AUT)',
              add: 'Aggiungi un nuovo autore responsabilità',
              fields: {
                autn: 'Nome di persona o Ente (AUTN)',
                auta: 'Indicazioni cronologiche (AUTA)',
                autz: 'Note (AUTZ)',
                autj: 'Ente schedatore (AUTJ)',
                auth: 'Codice identificativo (AUTH)',
              }
            },
            scan_measures: {
              title: 'Misure (MIS)',
              add: 'Aggiungi una nuova misura',
              fields: {
                misz: 'Tipo misura (MISZ)',
                misu: 'Unità di misura (MISU)',
                mism: 'Valore (MISM)',
                misv: 'Note (MISV)',
              }
            },
          },
          webgis: {
            title: 'WebGIS',
            subtitle: 'WebGIS delle geometrie del progetto',
          },
          wiki: {
            title: 'Wiki',
            subtitle: 'Norme di catalogazione',
          },
          placename_types: {
            title: 'Tipologie di Toponimi',
            subtitle: 'Tipologie di Toponimi',
            single: 'Tipologia di Toponimo',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
              references: 'Bibliografia',
              cards: 'Schede'
            },
            select: 'Seleziona un tipo di toponiomo'
          },
          places: {
            title: 'Toponimi',
            subtitle: 'Toponimi',
            single: 'Toponimo',
            fields: {
              code: 'Codice',
              name: 'Nome',
              archive: 'Fondo',
              description: 'Descrizione',
              link: 'Link',
              cards: 'Schede',
              placenames: 'Toponimi storici'
            },
            placenames_separator: 'Toponimi storici associati',
            select: 'Seleziona un toponiomo',
            uploader_text: 'Carica allegati da associare al toponimo',
            locations: {
              pivot: 'Campi di relazione della localizzazione al toponimo',
            },
            importer_archive_text: 'Seleziona un fondo di Toponimi da associare'
          },
          placenames: {
            title: 'Toponimi storici',
            subtitle: 'Toponimi storici',
            single: 'Toponimo storico',
            fields: {
              card: 'Toponimo',
              name: 'Nome',
              archive: 'Fondo',
              code: 'Codice',
              region: 'Regione',
              type: 'Tipologia di Toponimo',
              literal_transcript: 'Trascrizione letterale',
              latin_transcript: 'Trascrizione latina',
              notes: 'Note',
              cards: 'Toponimo'
            },
            select: 'Seleziona un toponiomo storico',
            relations_title: 'Seleziona i toponimi storici da associare alla scheda del toponimo',
            add_new: 'Aggiungi un nuovo toponimo storico',
            importer_archive_text: 'Seleziona un fondo di Toponimi storici da associare'
          }
        }
      },
      en: {
        menu: {
          cards: 'Cards',
          f_cards: 'F Cards',
          collections: 'Collections',
          scan_cards: 'SCAN Cards',
          lemmas: 'Lemmas',
          webgis: 'WebGIS',
          wiki: 'Wiki',
          places: 'Places',
          admin: 'Admin',
          placename_types: 'Placename Types',
          placenames: 'Historical Placenames',
          taxonomies: 'Eredità Culturali'
        },
        tips: {
          is_visible: 'If checked card will be visible for all archive users',
          is_published: 'If checked card will be visible for web portal and apps',
        },
        views: {
          f_cards: {
            title: 'ICCD Photographic Cards',
            subtitle: 'F Cards',
            single: 'F Card',
            fields: {
              code: 'Code',
              name: 'Name',
              description: 'Description',
              card: 'Card',
              archive: 'Archive',

              f_card_separator: 'F Card specific data',
              inventario: 'Inventary',
              archivio: 'Archive',
              fondo_collezione: 'Collection',
              ente_produttore: 'Producer Istitution',
              foglio_IGM: 'IGM Sheet',
              strisciata: 'Strip',
              numero_inventario_patrimoniale: 'Patrimonial inventary number',
              collocazione: 'Phisical location',
              codice_univoco: 'Unique code',
              definizione_oggetto: 'Object definition',
              unita_archivistica: 'Archival unit',
              indicazione_colore: 'Color',
              materia_tecnica_oggetto: 'Matter and technique',
              misure_formato: 'Measures and format',
              cronologia_generica: 'Generic chronology',
              cronologia_specifica: 'Specific chronology',
              autore_fotografia: 'Photo author',
              editore: 'Editor',
              categorie: 'Categories',
              soggetto: 'Subject',
              titolo: 'Title',
              luogo_ripresa: 'Photo place',
              data_ripresa: 'Photo date',
              cronologia_opera_fotografata: 'Artwork photo chronology',
              autore_opera_fotografata: 'Artwork photo author',
              iscrizioni_timbri: 'Stamp inscription',
              alterazioni_deterioramenti: 'Alterations deterioration',
              data_restauro: 'Restoration date',
              bibliografia_specifica: 'Specific bibliography',
              mostre: 'Exhibitions',
              numero_riproduzione: 'Reproduction number',
              numero_fotografia_originale: 'Original photograph number',
              collocazione_riproduzione_stampa: 'Location of print reproduction',
              compilatore_scheda: 'Card compiler',
              funzionario_responsabile: 'Responsible officer',
              note_lavoro: 'Working notes',
              collection: 'Collection',
              cm_separator: 'Certification / Data managing',
              rv_separator: 'Cards Relationships',
              type: 'Type',
              direction: 'Relationship direction',
              notes: 'Notes',
              codice_luogo: 'Place code'
            },
            importer_archive_text: 'Select an archive to associate',
            importer_media_text: 'Select an archive to associate',
            uploader_text: 'Upload documentation',
            location: {
              title: 'Associated GIS data'
            },
            f_certifications: {
              title: 'Certification records / Data managing (CM)',
              add: 'Add a certification record',
              fields: {
                cmr: 'Responsible (CMR)',
                cma: 'Year of drafting (CMA)',
                adp: 'Publication profile (ADP)',
                oss: 'Notes (OSS)'
              }
            },
            related_card: {
              name: 'Manage SCAN Card relationships',
              title: 'SCAB Cards Relationships',
              add_new: 'Add new relationship',
              tip: `Adding a relationship this card will be children`,
              fields: {}
            }
          },
          collections: {
            title: 'ICCD F Cards Collections and Funds',
            subtitle: 'F Cards Collections ',
            single: 'Collection',
            fields: {
              code: 'Code',
              name: 'Name',
              bibliography: 'Bibliography',
              fcards_count: 'F Card count'
            },
            select: 'Select a collections'
          },
          scan_cards: {
            title: 'ICCD SCAN Cards',
            subtitle: 'SCAN Cards',
            single: 'SCAN Card',
            fields: {
              code: 'Code',
              name: 'Name',
              description: 'Description',
              card: 'Card',
              archive: 'Archive',
              persons: 'Persons',

              tsk: 'Tipo scheda (TSK)',
              lir: 'Livello catalogazione (LIR)',
              nctr: 'Codice regione (NCTR)',
              nctn: 'Numero catalogo generale (NTCN)',
              esc: 'Ente schedatore (ESC)',
              ecp: 'Ente competente per tutela (ECP)',
              acce: 'Ente/soggetto responsabile (ACCE)',
              accw: 'Indirizzo web (ACCW)',
              ctb: 'Categoria generale (CTB)',
              set: 'Settore disciplinare (SET)',
              tbc: 'Tipo bene culturale (TBC)',
              ctg: 'Categoria disciplinare (CTG)',
              ogd: 'Definizione bene (OGD)',
              ogt: 'Tipologia/altre specifiche (OGT)',
              ogn: 'Denominazione/titolo (OGN)',
              rser: 'Tipo di relazione (RSER)',
              rses: 'Specifiche tipo relazione (RSES)',
              rez: 'Note sulle relazioni (REZ)',
              lcs: 'Stato (LCS)',
              lcr: 'Regione (LCR)',
              lcp: 'Provincia (LCP)',
              lcc: 'Comune (LCC)',
              lci: 'Indirizzo (LCI)',
              lcv: 'Altri percorsi/specifiche (LCV)',
              plv: 'Toponimo / Località (PLV)',
              pvz: 'Tipo di contesto (PVZ)',
              pve: 'Diocesi (PVE)',
              gel: 'Tipo di localizzazione (GEL)',
              get: 'Tipo di georeferenziazione (GET)',
              gep: 'Sistema di riferimento (GEP)',
              gec: 'Coordinate (GEC)',
              gecx: 'Coordinata x (GECX)',
              gecy: 'Coordinata y (GECY)',
              aff: 'Affidabilità (AFF)',
              gpbb: 'Descrizione sintetica (GPBB)',
              gpbt: 'Data (GPBT)',
              gpbu: 'Indirizzo web (GPBU)',
              dtzg: 'Fascia cronologica/periodo (DTZG)',
              dtzs: 'Specifiche (DTZS)',
              dtsi: 'Da (DTSI)',
              dtsv: 'Validità Da (DTSV)',
              dtsf: 'A (DTSF)',
              dtsl: 'Validità A (DTSL)',
              dtt: 'Note (DTT)',
              des: 'Descrizione del bene (DES)',
              aid: 'Apparato iconografico/decorativo (AID)',
              cmr: 'Responsabile (CMR)',
              cma: 'Anno di redazione (CMA)',
              adp: 'Profilo di pubblicazione (ADP)',
              cam: 'Caratteri ambientali beni immobili (CAM)',
              cd_separator: 'Identificazione (CD)',
              og_separator: 'Bene culturale (OG)',
              rv_separator: 'Relazioni (RV)',
              lc_separator: 'Localizzazione (LC)',
              ge_separator: 'Georeferenziazione (GE)',
              dt_separator: 'Cronologia / Definizione Culturale (DT)',
              da_separator: 'Dati analitici (DA)',
              do_separator: 'Documentazione (DO)',
              cm_separator: 'Certificazione / Gestione dati (CM)',
              other_fields: 'Altri campi',
              atb: 'Ambito culturale (ATB)',
              res: 'Dati provenienza archeologica (RES)',
              nsc: 'Approfondimenti storico-critici (NSC)',
              mt_separator: 'Dati tecnici (MT)',
              nvc: 'Provvedimenti amministrativi-specifiche (NVC)',
              notes: 'Altre note',
              ro: 'Risorse Online',
              accr: 'Riferimento cronologico (ACCR)',
              accs: 'Note (ACCS)'
            },
            importer_archive_text: 'Seleziona un fondo da associare alle schede',
            uploader_text: 'Upload documentation to card',
            location: {
              title: 'Dati GIS associati'
            },
            scan_documentations: {
              title: 'Documentazione associata alla scheda',
              add: 'Aggiungi un nuovo elemento della documentazione SCAN (DO)',
              fields: {
                dcmn: 'Codice identificativo (DCMN)',
                dcmp: 'Tipo (DCMP)',
                dcmm: 'Titolo/didascalia (DCMM)',
                dcma: 'Autore (DCMA)',
                dcmr: 'Riferimento cronologico (DCMR)',
                dcme: 'Ente proprietario (DCME)',
                dcmc: 'Collocazione (DCMC)',
                dcmw: 'Indirizzo web (DCMW)',
                dcmk: 'Nome file (DCMK)',
                dcmt: 'Note (DCMT)',
                bib: 'Bigliografia (BIB)'
              }
            },
            scan_historicals: {
              title: 'Notizie storiche associate alla scheda',
              add: 'Aggiungi un nuova notizia storica',
              fields: {
                dtns: 'Notizia di sintesi (DTNS)',
                dtnn: 'Notizia di dettaglio (DTNN)',
                dtr: 'Riferimento cronologico (DTR)',
                dtg: 'Specifiche riferimento cronologico (DTG)'
              }
            },
            scan_certifications: {
              title: 'Voci di certificazione / gestione dati (CM)',
              add: 'Aggiungi un nuova voce di certificazione/gestione dati',
              fields: {
                cmr: 'Responsabile (CMR)',
                cma: 'Anno di redazione (CMA)',
                adp: 'Profilo di pubblicazione (ADP)',
                oss: 'Note (OSS)'
              }
            },
            related_card: {
              name: 'Gestisci le relazioni tra Schede SCAN',
              title: 'Relazioni esistenti con altre Schede SCAN',
              add_new: 'Aggiungi un nuova relazione',
              tip: `Creando una relazione da questa scheda, la scheda associata diventa "figlia" dell'attuale`,
              fields: {}
            }
          },
          webgis: {
            title: 'WebGIS',
            subtitle: 'WebGIS of project geometries',
          },
          wiki: {
            title: 'Wiki',
            subtitle: 'Cataloguing Regulations',
          },
          placename_types: {
            title: 'Placename Types',
            subtitle: 'Manage Placename Types',
            single: 'Placename Type',
            fields: {
              code: 'Code',
              name: 'Name',
              description: 'Description',
              references: 'References',
              cards: 'Cards'
            },
            select: 'Select type'
          },
          places: {
            title: 'Places',
            subtitle: 'Manage Places',
            single: 'Place',
            fields: {
              code: 'Code',
              name: 'Name',
              archive: 'Archive',
              description: 'Description',
              link: 'Link',
              cards: 'Cards',
              placenames: 'Placenames'
            },
            placenames_separator: 'Associated Placenames',
            select: 'Select a placename',
            uploader_text: 'Upload attachment to placename',
            locations: {
              pivot: 'Relation field to placename localizations',
            },
            importer_archive_text: 'Select an Archive to associate'
          },
          placenames: {
            title: 'Placenames',
            subtitle: 'Manage Placenames',
            single: 'Placename',
            fields: {
              card: 'Place',
              name: 'Name',
              archive: 'Archive',
              code: 'Code',
              region: 'Region',
              type: 'Placename Type',
              literal_transcript: 'Literal transcript',
              latin_transcript: 'Latin transcript',
              notes: 'Notes',
              cards: 'Places'
            },
            select: 'Select a placename',
            relations_title: 'Relations beetween placanemes and places',
            add_new: 'Add new placename',
            importer_archive_text: 'Select an Archive to associate'
          }
        }
      },
    }
}

export default ereditaculturali