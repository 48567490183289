import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import plugins from '@/plugins'

const messages = {
  en: {
    global: {
      menu: 'Menu',
      more: 'Find out more...',
      back: 'Go Back',
      hello: 'Hello, ',
      of: 'of',
      select: 'Select...',
      no_results: 'No results',
      select_one: `Select one`,
      search_everywhere: `Search everywhere`,
      legend: 'Legenda',
      hello_welcome: ' and welcome',
      success: {
        logout: 'Successfully logged out'
      }
    }, 
    tips: {
      save_before: 'Save before',
      remember_update: 'Remember to save Card.',
      remember_save: 'Card will be automatically saved.'
    },
    actions: {
      label: 'Actions',
      new: 'New',
      edit: 'Edit',
      delete: 'Delete',
      remove: 'Remove',
      save: 'Save',
      update: 'Update',
      cancel: 'Cancel',
      confirm: 'Confirm',
      signin: 'Sign In',
      import: 'Import',
      import_media: 'Media',
      import_cards: 'Cards',
      upload_files: 'Upload media',
      export_shapefile: 'Export Shapefile',
      select: (ctx) => `Select ${ctx.named('name')}`,
      select_one: `Select`,
      export_cards: 'Export',
      export: 'Export',
      group: 'Azioni di gruppo',
      editor: {
        list: 'list',
      },
      search: 'Search',
      download_import_model: 'Download model',
      import_shapefile: `Import Shapefile`,
      register: 'Register',
      password_reset: 'Reset password',
      resend_verification: 'Invia nuovamente verifica della email',
      go_to_doc: 'Go to document',
      backto: 'Back to list',
      send: 'Send',
      communications: {
        sent: 'Message sent'
      },
      show: 'Show',
      hide: 'Hide'
    },
    components: {
      crud: {
        card_owned_by: 'Card owned by',
        card_owner_info: 'For info and report, contact archive managers',
        website_card_url: 'Show Card in website'
      },
      lemmatizer: {
        associate: 'Associate',
        update: 'Update',
        new: 'Add a new lemma',
        no_lemma: 'No lemma associated',
        save_before: 'Save before'
      },
      chart: {
        cards: 'Cards'
      },
      map: {
        want_add_location: 'Do you want add coordinates for this location?',
        add_location: 'Add coordinates',
        tip_double_click: 'Double click on map to move the marker or drag in desired position',
        place_coordinates: 'Coordinates in this location',
        popup_default: 'This point define current location position.',
        remove_location: 'Remove coordinates',
        no_data: 'No geografic data to show'
      },
      geometry: {
        add_point: 'Add point',
        create: 'Create',
        remove: 'Remove',
        want_create: (ctx) => `Do you want create a ${ctx.named('type')} geometry for this card?`,
        last_point: `The last point must coincide with first to close polygon`,
        digitize: `To digitize a geometry click on toolbar on top-right of map on the avaiables geometry type and click on map each vertex needed. Remember to click on "Save" to close geometry digitizing. Click on edit icon to modify position of each vertex and create new ones. Click on delete to delete geometry.`
      },
      file_manager: {
        no_content: 'No associated content.',
        is_cover: 'Image is cover',
        set_as_cover: 'Set as cover'
      },
      relatizer: {
        add: 'Add',
        update: 'Update',
        new: 'Add new',
        no_related: 'No related card found',
        save_before: 'Save before'
      },
      threed_visualizer: {
        select_archive: 'Select Archive for manage 3D data',
        scene_elements: '3D Elements',
        archive_missing_data: `This archive isn't yet ready for 3d data`
      },
      locations_manager: {
        title: 'Associate GIS Places to Card',
        tips: `With this tool your can associate GIS Places to Card. On the left your can see associated places. On right a map can associate exitings places. With the following button you can add new missing places.`,
        remove_location: 'Do you want proceed with deletion?',
        remove_content: `Removing association will not remove GIS Place itself. The operation cannot be canceled.`,
        add_location: 'New GIS Place',
        location_type: 'Type',
        location_cards: 'Cards',
        associate: 'Associate GIS Place',
        no_cards: 'No associated card found',
        no_entities: 'No associated entities found',
        no_geometry: 'No associated GIS geometry found',
        save_before: 'Save before',
        base_map_name: 'Base map'
      },
      relations_manager: {
        title: 'Card to card relations',
        tips: `With this tool your can associate Cards to Card. On the left your can see associated cards. On right you can specify association details. With the following button you can add new card association.`,
        remove_relation: 'Remove association',
        add_relation: 'Add',
        no_relations: 'No associations found',
        select_relation: 'Edit existing relation or add a new one',
        select_model: 'Select a card to associate',
        save_before: 'Save before',
        success_update: 'Successfully updated',
        success_order: 'Card order successfully saved',
        remove_related: 'Do you want remove association?',
        remove_related_content: 'Proceeding with this operation relation will be removed',
        delete_related: 'Do you want remove relation?',
        delete_related_content: 'Proceeding with this operation relation will be removed',
        add_or_select: 'Or compile data for a new card',
        related_missing: 'Missing related selection'
      }
    },
    modals: {
      delete: {
        title: `Do you want proceed with deletion?`,
        content: `Following operation cannot be canceled`,
        confirmed: (ctx) => `${ctx.named('name')} deleted`,
      },
      update: {
        confirmed: 'Updated!'
      },
      save: {
        confirmed: 'Saved!'
      },
      download: {
        confirmed: 'Downloaded!'
      },
      importer: {
        title: (ctx) => `Import data for ${ctx.named('name')} by file`,
        media_title: (ctx) => `Import media for ${ctx.named('name')}`,
        content: `Upload a file from your PC drag just below and click on "Upload". After upload completed, click on "Import" to execute data importing.`,
        media_content: `Upload media from your PC drag just below and then click on "Upload". After upload complete, click on "Import" to exectute media importing and associations with cards.<br/>For automatic association each file must have "code" field of Card as filename.`,
        confirmed: `Importing started. It can takes some minutes.`,
        warnings: (ctx) => `<strong>NOTE</strong>: Can be uploaded max <strong>${ctx.named('max_file_uploads')} files</strong> at once, and with a maxmimum weight of <strong>${ctx.named('post_max_size')}B</strong> for each one.`,
        uploaded: `Media successfully uploaded and ready to elaboration.`,
        upload_only: `Upload only: `,
        type_not_allowed: 'Questo tipo di file non è supportato',
        shapefile_title: `Shapefile Import`,
        shapefile_content: `Import GIS vectorial data from Shapefile`
      },
      exporter: {
        title: (ctx) => `Exporting ${ctx.named('name')} data`,
        content: `Export card in Excel or PDF format. In order to export just some cards, apply filters before and than reopen this modal to start exporting.`,
        confirmed: `Exporting started. It can takes some minutes. Will be send an email with attachment request.`,
        shapefile_content: `Export geometries in ESRI Shapefile format. Select a geometry type and a category of data. Then click on Export button.`,
      },
      coverize: {
        confirmed: 'Image assigned as cover.'
      },
      confirm: {
        title: 'Confirm',
        content: `Do you want confirm operation?`
      },
      clone: {
        title: 'Card cloning',
        content: 'Do you want make a copy of this Card?',
        confirmed: 'Card cloned successfully'
      },
      error: {
        import: 'Import failed',
        upload: 'File upload failed',
        upload_file_type: 'File Format not recognized',
        missing_archive: 'Missing archive',
        related: 'Error occurred when creating related card',
        missing_category: 'Please select a category',
        missing_geometry: 'Please select a geometry',
        lemma_attach: `Error occurred when attaching lemma`,
        lemma_update: 'Error occurred when updating lemma',
        export: `Error occuring when file exporting`,
        user_registration: `Error occuring when registrating user`,
        no_parent: (ctx) => `No ${ctx.named('name')}`,
        max_file_uploads_exceeded: 'Max file upload number exceeded'
      },
      entity: {
        title: (ctx) => `Edit ${ctx.named('name')}`,
      }
    },
    menu: {
      admin: 'Admin',
      login: 'Login',
      logout: 'Logout',
      register: 'Register',
      home: 'Dashboard',
      projects: 'Project',
      users: 'Users',
      cards: 'Cards',
      archives: 'Archives',
      locations: 'Locations',
      classifications: 'Classifications',
      tags: 'Tags',
      mapviewer: 'Map',
      medias: 'Media',
      profile: 'Profile',
      logs: 'Logs',
      tenants: 'Tenants',
      persons: 'Persons',
      person_types: 'Person Types',
      persons_separator: 'Persons',
      location_person_types: 'Location Types',
      card_person_types: 'Card Types',
      qualifications: 'Qualifications',
      registries: 'Registries',
      card_containers: 'Card Containers',
      card_container_types: 'Card Container Types',
      card_container_separator: 'Card Containers',
      references: 'Bibliographic References',
      roles_permissions: 'Roles and permissions',
      reference_sources: 'Sources',
      communications: 'Communications',
      taxonomies: 'Taxonomies',
      bibliography: 'Bibliography'
    },
    pagination: {
      results: 'Results',
      prev: 'Previous',
      next: 'Next',
      last: 'Last page'
    },
    profile: {
      confirmed: 'Confirmed'
    },
    roles: {
      admin: 'Admin',
      archive_manager: 'Archive Manager',
      card_manager: 'Card compiler',
      tenant_manager: 'Tenant Manager',
      viewer: 'Viewer',
      person_manager: 'Person Manager',
      card_container_manager: 'Card Container Manager',
      registry_manager: 'Registry Manager',
      project_manager: 'Project Manager',
      person_compiler: 'Perso Compiler'
    },
    home: {
      hello: 'Hello',
      profile: 'Your profile',
      last_cards: 'Last created cards',
      platform_configuration: 'Platform configuration',
      platform_upload: `Max upload size`,
      platform_file_size: `Max single file upload`,
      platform_file_number: `Max file number upload`
    },
    views: {
      dashboard: {
        title: 'Welcome!',
        subtitle: '',
        single: '',
        fields: {}
      },
      unauthorized: {
        title: 'You cannot access to this page',
        subtitle: `You doesn't have permission to access this section`,
        single: '',
        fields: {}
      },
      login: {
        title: 'Sign In',
        subtitle: 'Insert your credentials',
        single: '',
        fields: {
          name: 'Name',
          surname: 'Surname',
          email: 'Email',
          password: 'Password',
          confirm_password: 'Confirm password',
          institution: 'Institution'
        },
        missing_email: 'Insert your email',
        reset_password: 'An email with link to reset your password has been sent.'
      },
      register: {
        title: 'Register',
        lost_password: 'Lost Password',
        request:  `Your request was successful.<br/>You'll recieve an email with a verification link. Click on that link to verify your account and you'll be able to access to platform`,
        resend_verification: `A new email verification was send to your mailbox.`,
        email_already_verified: 'Your email has been already verified. Click on following button to login',
        email_verified: 'Your email has been verified. Click on following button to login',
        email_to_verify: 'Your email must be verified.'
      },
      lostpassword: {
        title: 'Reset your password',
        missing_data: 'To reset your password go to signin paga and click on "Lost password"',
        password_changed: 'User password was changed successfully. Please signin.'
      },
      projects: {
        title: 'Projects',
        subtitle: 'Manage Projects',
        single: 'Project',
        fields: {
          code: 'Code',
          name: 'Name',
          introduction: 'Introduction',
          location: 'Location',
          cost: 'Cost (in €)',
          card_containers_count: 'Card Containers',
          card_containers: 'Card Containers',
          tenants: 'Tenant',
          users: 'Users'
        }
      },
      users: {
        title: 'Users',
        subtitle: 'Manage Users',
        single: 'User',
        fields: {
          name: 'Name',
          surname: 'Surname',
          email: 'Email',
          roles: 'Roles',
          password: 'Password',
          confirm_password: 'Confirm password',
          cards_count: 'Cards',
          email_verified_at: 'Verified at',
          institution: 'Institution',
          tenants: 'Tenants',
          favourites: 'Favourites',
          verified: 'Verified',
          created_at: 'User from',
          associations: 'Associations',
          archives: 'Archives',
          projects: 'Projects',
          registries: 'Registries',
        },
        profile: {
          title: 'Your profile'
        }
      },
      cards: {
        title: 'Cards',
        subtitle: 'Manage Cards',
        single: 'Card',
        created_by: 'Created by',
        created_at: 'Created at',
        updated_at: 'Last update',
        fields: {
          name: 'Name',
          code: 'Code',
          archive_id: 'Archive',
          archive: 'Archive',
          user_id: 'Compiler',
          user: 'Compiler',
          is_visible: 'Visible',
          is_published: 'Public',
          location: 'Location',
          location_id: 'Location',
          tags: 'Tags',
          card_separator: 'Base card data',
          lemma_separator: 'Card associated lemmas',
          references: 'References',
          reference_sources: 'Reference sources'
        },
        files: {
          types: {
            document: 'Documentation',
            raster: 'Raster',
            photo: 'Photo',
            image: 'Picture',
            threed_model: '3D Model', 
            threed_texture: '3D Texture', 
            spheric_picture: '360° Picture'
          },
          fields: {
            description: 'File description',
            license: `Use License`,
            reference: 'References',
            crs: 'CRS',
            ne_lat: 'Latitude NE',
            ne_lng: 'Longitude NE',
            sw_lat: 'Latitude SW',
            sw_lng: 'Longitude SW',
            file_id: 'Associated file',
            elevation: 'Elevation',
            file_select: 'Select a file to associate'
          }
        },
        persons: {
          separator: 'Persons',
          name: 'Card Persons',
          title: 'Associated Card Persons',
          add_new: 'Associate new Person to Card',
          fields: {
            card_person_type_id: 'Card Person relationship type',
            description: 'Description',
            tags: 'Tags'
          }
        }
      },
      archives: {
        title: 'Archives',
        subtitle: 'Manage Archives',
        single: 'Archive',
        fields: {
          name: 'Name',
          code: 'Code',
          cards_count: 'Cards',
          users: 'Users',
          tenants: 'Tenants'
        },
        select: `Select archive`
      },
      tenants: {
        title: 'Tenants',
        subtitle: 'Manage Tenants',
        single: 'Tenant',
        fields: {
          name: 'Name',
          code: 'Code',
          namespace: 'App Namespace',
          url_production: 'Website URL Production',
          url_staging: 'Website URL Staging',
          url_development: 'Website URL Development',
          url_card: 'Website Card page base URL',
          email: 'Email of sender for user notifications',
          logo: 'Logo',
          color: 'Color',
          users_count: 'Users',
          archives_count: 'Archives',
          users: 'Users',
          archives: 'Archives',
          projects: 'Projects',
          projects_count: 'Projects'
        },
        select: `Select tenant`
      },
      locations: {
        title: 'GIS Locations',
        subtitle: `Manage Cards GIS Locations`,
        single: 'GIS Location',
        fields: {
          name: 'Name',
          address: 'Address',
          location: 'Location',
          accuracy: 'Accuracy',
          location_latitude: 'Latitude',
          location_longitude: 'Longitude',
          geometries_separator: 'GIS Geometries Fields',
          polygon: 'Polygon',
          pointz: 'PointZ',
          multipointz: 'MultiPointZ',
          polygonz: 'PolygonZ',
          linestringz: 'LineStringZ',
          geometries: 'Geometries',
          classification_id: 'Classification',
          card_id: 'Card'
        },
        add: 'Add GIS location'
      },
      classifications: {
        title: 'Classifications',
        subtitle: `Manage Classifications`,
        single: 'Classification',
        fields: {
          name: 'Name',
          type: 'Type',
          color: 'Color',
          description: 'Description',
          location: 'Location',
          location_id: 'Location',
          locations_count: 'Locations'
        }
      },
      tags: {
        title: 'Tags',
        subtitle: 'Manage Tags',
        single: 'Tag',
        fields: {
          name: 'Name',
          cards_count: 'Cards'
        }
      },
      mapviewer: {
        title: 'Map Viewer',
        subtitle: 'Map Viewer for georeferenced raster images',
        single: 'Map Viewer'
      },
      medias: {
        title: 'Media',
        subtitle: 'Media manager',
        single: 'Media',
        fields: {
          file: 'File',
          original: 'Original',
          card: 'Card',
          code: 'Code',
          description: 'Description',
          format: 'Format',
          format_select: 'Select a format'
        },
      },
      logs: {
        title: 'Logs',
        subtitle: 'API Platform Logs',
        single: 'Log',
        fields: {
          causer: 'Causer',
          status: 'Status',
          event: 'Event',
          route: 'Route',
          created_at: 'Created at'
        }
      },
      persons: {
        title: 'Persons',
        subtitle: 'Manage Persons',
        single: 'Person',
        fields: {
          code: 'Code',
          name: 'Name',
          pre_name: 'Prename',
          registries: 'Registries',
          first_name: 'First Name',
          middle_name: 'Middle Name',
          last_name: 'Last Name',
          registry: 'Registry',
          title: 'Title',
          nick_name: 'Nickname',
          initials: 'Initials',
          sex: 'Sex',
          birth_date: 'Birthdate',
          birth_place: 'Birthplace',
          death_date: 'Deathdate',
          death_place: 'Deathplace',
          from_date: 'Textual Birthdate',
          to_date: 'Textual Deathdate',
          biography: 'Biography',
          notes: 'Notes',
          origin: 'Origin',
          nationality: 'Nationality',
          website: 'Website URL',
          person_type_id: 'Person Type',
          person_type: 'Person Type',
          user_id: 'Compiler',
          user: 'Compiler',
          is_visible: 'Visible',
          is_published: 'Public',
          locations: 'Locations',
          location_id: 'Location',
          card_separator: 'Base card data',
          person_separator: 'Person data',
          qualifications: 'Qualifications',
          location_person_type: 'Location Person Type',
          tags: 'Tag',
          auta: 'Indicazioni cronologiche (AUTA)',
          certifications_separator: 'Person Certifications'
        },
        person_certifications: {
          title: 'Person certifications',
          add: 'Add new certification entry',
          fields: {
            name: 'Name',
            responsible: 'Responsible',
            year: 'Year',
            note: 'Note'
          }
        }
      },
      person_types: {
        title: 'Person Types',
        subtitle: 'Manage Person Types',
        single: 'Person Type',
        fields: {
          code: 'Code',
          name: 'Name',
          description: 'Description',
        }
      },
      qualifications: {
        title: 'Qualifications',
        subtitle: 'Manage Qualifications',
        single: 'Qualification',
        fields: {
          code: 'Code',
          name: 'Name',
          description: 'Description',
        }
      },
      location_person_types: {
        title: 'Location Person Types',
        subtitle: 'Manage Location Person Types',
        single: 'Location Person Type',
        fields: {
          code: 'Code',
          name: 'Name',
          description: 'Description',
        }
      },
      card_person_types: {
        title: 'Card Person Types',
        subtitle: 'Manage Card Person Types',
        single: 'Card Person Type',
        fields: {
          code: 'Code',
          name: 'Name',
          description: 'Description',
        }
      },
      registries: {
        title: 'Registries',
        subtitle: `Manage Person Registries`,
        single: 'Registry',
        fields: {
          name: 'Name',
          code: 'Code',
          description: 'Description',
          persons_count: 'Persons',
          users: 'Users',
          tenants: 'Tenants'
        },
        select: `Select a registry`
      },
      card_containers: {
        title: 'Card Containers',
        subtitle: `Manage Card Containers`,
        single: 'Card Container',
        fields: {
          name: 'Name',
          code: 'Code',
          is_visible: 'Visible',
          is_published: 'Published',
          description: 'Description',
          user_id: 'Compiler',
          card_container_type_id: 'Container Type',
          users: 'Users',
          tenants: 'Tenant',
          cards_count: 'Cards',
          projects: 'Projects',
          tags: 'Tags',
          show_track: 'Show track',
          order_by_date: 'Order by date'
        },
        select: `Select a Container`
      },
      card_container_types: {
        title: 'Card Container Types',
        subtitle: 'Manage Card Container Types',
        single: 'Card Container Type',
        fields: {
          code: 'Code',
          name: 'Name',
          description: 'Description',
        }
      },
      references: {
        title: 'Bibliographic References',
        subtitle: 'Manage Bibliographic References',
        single: 'Bibliographic Reference',
        fields: {
          citation: 'Citation',
          title: 'Title',
          author: 'Author',
          year: 'Year',
          cards: 'Cards',
          is_published: 'Published',
          cards_count: 'Cards',
          reference_sources_count: 'Sources'
        }
      },
      reference_sources: {
        title: 'Bibliographic Reference Sources',
        subtitle: 'Manage Sources',
        single: 'Bibliographic Reference Source',
        fields: {
          name: 'Name',
          references: 'References',
          is_published: 'Published',
          color: 'Color',
          references_count: 'Reference',
          reference_sources_count: 'Sources'
        }
      },
      communications: {
        title: 'Communications',
        subtitle: 'Send communications to platform users',
        single: 'Communication',
        fields: {
          subject: 'Subject',
          body: 'Message',
          users: 'Users',
          roles: 'Roles',
          tenants: 'Tenants',
          archives: 'Archives',
          registries: 'Registries',
          projects: 'Projects',
        },
      },
      roles: {
        select: 'Select a role'
      }
    }
  },
  it: {
    global: {
      menu: 'Menu',
      more: 'Scopri tutto...',
      back: 'Torna indietro',
      hello: 'Ciao, ',
      of: 'di',
      select: 'Seleziona...',
      no_results: 'Nessun risultato',
      search_everywhere: `Cerca su tutti i campi`,
      select_one: `Selezionare un'opzione`,
      type: 'Tipologia',
      legend: 'Legenda',
      hello_welcome: ' e benvenuto',
      select_no_value: 'Nessun valore',
      no_related: 'Nessun record associato',
      no_preview: 'Nessun anteprima'
    },
    tips: {
      save_before: 'E\' necessario salvare prima',
      remember_update: 'Ricordati di salvare la scheda.',
      remember_save: 'La scheda verrà salvata in automatico.'
    },
    actions: {
      label: 'Azioni',
      new: 'Aggiungi',
      edit: 'Modifica',
      delete: 'Elimina',
      remove: 'Rimuovi',
      save: 'Salva',
      update: 'Aggiorna',
      cancel: 'Annulla',
      confirm: 'Conferma',
      signin: 'Accedi',
      import: 'Importa',
      import_media: 'Media',
      import_cards: 'Schede',
      upload_files: 'Carica media',
      file_fields: 'Campi del file',
      select: (ctx) => `Seleziona ${ctx.named('name')}`,
      export_cards: 'Esporta',
      export: 'Esporta',
      editor: {
        list: 'lista',
      },
      search: 'Cerca',
      select_one: 'Seleziona un valore',
      download_import_model: 'Scarica modello',
      export_shapefile: 'Esporta Shapefile',
      import_shapefile: `Importa Shapefile`,
      register: 'Registrati',
      password_reset: 'Reimposta password',
      resend_verification: 'Invia nuovamente verifica della email',
      go_to_doc: 'Vai al documento',
      backto: 'Torna alla lista',
      send: 'Invia',
      communications: {
        sent: 'Messaggio inviato'
      },
      show: 'Mostra',
      hide: 'Nascondi',
      clear_canvas: 'Riseleziona archivio'
    },
    components: {
      crud: {
        card_owned_by: 'Scheda appartenente a',
        card_owner_info: 'Per informazioni e segnalazioni contattare i gestori del fondo',
        website_card_url: 'Mostra la scheda sul sito web'
      },
      lemmatizer: {
        associate: 'Associa',
        update: 'Aggiorna',
        new: 'Aggiungi nuovo',
        no_lemma: 'Nessuno associato',
        save_before: 'Prima salvare la scheda principale'
      },
      chart: {
        cards: 'Schede'
      },
      map: {
        want_add_location: 'Vuoi aggiungere delle coordinate per questo luogo?',
        add_location: 'Aggiungi coordinate',
        tip_double_click: 'Fai doppio click sulla mappa per spostare il marker o trascinalo nella posizione desiderata',
        place_coordinates: 'Coordinate in questo punto',
        popup_default: 'Questo punto definisce la posizione del luogo corrente.',
        remove_location: 'Rimuovi coordinate',
        no_data: 'Non ci sono dati geografici da mostrare'
      },
      geometry: {
        add_point: 'Aggiungi punto',
        create: 'Crea',
        remove: 'Rimuovi',
        want_create: (ctx) => `Vuoi creare una geometria ${ctx.named('type')} per questa scheda?`,
        last_point: `L'ultimo punto per chiudere il poligono deve coincidere col primo punto creato`,
        digitize: `Per digitalizzare una geometria clicca sulla barra degli strumenti in alto a destra selezionando uno dei tipi di geometrie disponibili. Clicca quindi sulla mappa per digitalizzare ogni vertice necessario. Clicca sul pulsante di modifica per modificare la posizione e l'aspetto della geometria. Clicca sul pulsante di eliminazione per eliminarla.`,
        centroid: `Centroide calcolato`
      },
      file_manager: {
        no_content: 'Nessun contenuto associato.',
        is_cover: 'Immagine principale',
        set_as_cover: 'Imposta come principale'
      },
      relatizer: {
        add: 'Aggiungi',
        update: 'Aggiorna',
        new: 'Aggiungi una nuovo correlato',
        no_related: 'Nessuna scheda associata',
        save_before: 'Prima di procedere aggiungere la correlazione'
      },
      threed_visualizer: {
        select_archive: 'Seleziona un Fondo Archeologico per gestire i suoi dati 3D',
        scene_elements: 'Elementi 3D',
        select_element: `Seleziona un elemento 3D dall'elenco o dalla mappa`,
        archive_missing_data: `Questo archivio non è ancora impostato per gestire dati 3D`
      },
      locations_manager: {
        title: 'Associa i Luoghi GIS alla scheda',
        tips: `Con lo strumento è possibile associare Luoghi GIS alla scheda. A sinistra è presente l'elenco dei luoghi associati. A destra la mappa consente di aggiungere ulteriori luoghi preesistenti. Con il pulsante a fianco è possibile aggiungere luoghi mancanti.`,
        remove_location: 'Vuoi procedere con la rimozione del luogo dalla scheda?',
        remove_content: `La rimozione non comporta l'eleminazione del Luogo GIS dall'archivio. L'operazione non può essere annullata.`,
        add_location: 'Nuovo Luogo GIS',
        location_type: 'Tipo',
        location_cards: 'Schede',
        associate: 'Associa Luogo GIS',
        no_cards: 'Nessuna scheda associata',
        no_entities: 'Nessuna scheda associata',
        no_geometry: 'Nessuna geometria GIS presente',
        save_before: 'Salvare la scheda prima di associare Luoghi GIS',
        base_map_name: 'Mappa di base'
      },
      relations_manager: {
        title: 'Relaziona le schede alla scheda',
        tips: `Con lo strumento è possibile creare relazioni tra la scheda attuale e le altre. Clicca sul bottone qui a destra per creare una nuova relazione. Clicca sulle voci qui in basso per gestire le relazioni già associate.`,
        remove_relation: 'Rimuovi relazione',
        add_relation: 'Aggiungi relazione',
        no_relations: 'Nessuna relazione alla scheda',
        select_relation: 'Modifica una relazione esistente o aggiungine una nuova',
        select_model: 'Seleziona una scheda da associare',
        save_before: 'Salvare la scheda prima di associare relazioni',
        success_update: 'Aggiornato con successo',
        success_order: 'Ordine salvato con successo',
        remove_related: 'Vuoi rimuovere la relazione?',
        remove_related_content: 'Procedendo rimuoverai la relazione con la scheda attuale',
        delete_related: 'Vuoi rimuovere la scheda?',
        delete_related_content: 'Procedendo rimuoverai la scheda correlata e la sua relazione con la scheda attuale',
        add_or_select: 'Oppure compila i dati di una nuova scheda',
        related_missing: 'Manca la selezione della scheda correlata'
      }
    },
    modals: {
      delete: {
        title: `Vuoi procedere con l'eliminazione della scheda?`,
        content: `L'operazione una volta eseguita non può essere annullata`,
        confirmed: (ctx) => `${ctx.named('name')} eliminato`,
        title_named: (ctx) => `Vuoi procedere con l'eliminazione di ${ctx.named('name')}`,
      },
      update: {
        confirmed: 'Aggiornato!'
      },
      save: {
        confirmed: 'Salvato!'
      },
      download: {
        confirmed: 'Scaricato!'
      },
      importer: {
        title: (ctx) => `Importazione dei dati di ${ctx.named('name')} tramite file`,
        media_title: (ctx) => `Importazione dei media per ${ctx.named('name')}`,
        content: `Carica un file dal tuo computer trascinandolo qui sotto e poi clicca sul bottone di upload. Poi clicca sul pulsante "Importa" per importare i dati.`,
        media_content: `Carica i media dal tuo computer trascinandolo qui sotto e poi clicca sul bottone di upload. Poi clicca sul pulsante "Importa" per importare i files e associarli alle schede in automatico.<br/>Per associarli in automatico i file devono avere come nome il "codice" della scheda.`,
        confirmed: `Procedura di importazione avviata. Potrebbe durare qualche minuto a seconda la dimensione del file.`,
        warnings: (ctx) => `<strong>NOTA</strong>: Possono essere caricati al massimo <strong>n. ${ctx.named('max_file_uploads')} files</strong> alla volta, per un peso massimo di <strong>${ctx.named('post_max_size')}B</strong>.`,
        uploaded: `File caricati con successo e pronti per l'elaborazione.`,
        upload_only: `Carica solo: `,
        type_not_allowed: 'Questo tipo di file non è supportato',
        single_file_fields: `<strong>NOTA</strong>: I campi del file vengono applicati a tutti i file scelti per l'importazione`,
        shapefile_title: `Importazione Shapefile`,
        shapefile_content: `Importa dati GIS vettoriali dal formato Shapefile`,
        radio_upload_file: 'Carica un file',
        radio_choose_file: 'Scegli un file dal Media manager'
      },
      exporter: {
        title: (ctx) => `Esportazione dei dati di ${ctx.named('name')}`,
        content: `Esporta le schede nel formato Excel o PDF. Per esportare una parte di schede, applicare prima i filtri e poi riaprire la modale di esportazione.`,
        confirmed: `Procedura di esportazione avviata. Potrebbe durare qualche minuto a seconda la dimensione del file. Verrà inviata una mail all'utente con l'allegato richiesto.`,
        shapefile_content: `Exporta le geometrie in ESRI Shapefile. Seleziona un tipo di geometria e una categoria di dati. Poi clicca su Esporta.`,
      },
      coverize: {
        confirmed: 'Immagine impostata come principale.'
      },
      confirm: {
        title: 'Conferma',
        content: `Vuoi confermare l'operazione?`
      },
      clone: {
        title: 'Duplicazione scheda',
        content: 'Vuoi effettuare una copia di questa scheda?',
        confirmed: 'Scheda clonata con successo'
      },
      error: {
        import: 'Importazione fallita',
        upload: 'Caricamento dei files fallito',
        upload_file_type: 'Formato file non riconosciuto',
        missing_archive: 'Archivio/Fondo mancante',
        related: 'Errore durante la creazione di una scheda associata',
        missing_category: 'Per favore selezionare una categoria',
        missing_geometry: 'Per favore selezionare una geometria',
        lemma_attach: `Si è verificato un errore durante l'operazione di associazione`,
        lemma_update: `Si è verificato un errore durante l'operazione di aggiornamento dell'associazione`,
        export: `Si è verificato un errore durante l'esportazione del file`,
        user_registration: `Si è verificato un errore durante la creazione dell'utente`,
        no_parent: (ctx) => `${ctx.named('name')} non associati`,
        max_file_uploads_exceeded: 'Numero massimo di file caricabili superato'
      },
      entity: {
        title: (ctx) => `Modifica ${ctx.named('name')}`,
      }
    },
    menu: {
      admin: 'Admin',
      login: 'Accedi',
      logout: 'Esci',
      register: 'Registrati',
      home: 'Dashboard',
      projects: 'Progetti',
      users: 'Utenti',
      cards: 'Schede',
      archives: 'Fondi',
      roles_permissions: 'Ruoli e permessi',
      locations: 'Luoghi GIS',
      classifications: 'Classificazioni',
      tags: 'Tag',
      mapviewer: 'Mappa',
      profile: 'Il mio profilo',
      medias: 'Media',
      logs: 'Log',
      tenants: 'Tenant',
      persons_separator: 'Persone',
      persons: 'Persone',
      person_types: 'Tipologie di Persone',
      qualifications: 'Qualifiche',
      location_person_types: 'Tipologie Luoghi',
      card_person_types: 'Tipologie Schede',
      registries: 'Anagrafiche',
      card_containers: 'Contenitori Schede',
      card_container_types: 'Tipi Contenitori Schede',
      card_container_separator: 'Contenitori',
      references: 'Bibliografia',
      reference_sources: 'Fonti',
      communications: 'Comunicazioni',
      bibliography: 'Bibliografia',
      taxonomies: 'Tassonomie',
    },
    pagination: {
      results: 'Risultati',
      prev: 'Precedente',
      next: 'Successiva',
      last: 'Ultima pagina'
    },
    profile: {
      confirmed: 'Confermato'
    },
    roles: {
      admin: 'Amministratore',
      archive_manager: 'Gestore Fondi',
      card_manager: 'Compilatore Schede',
      tenant_manager: 'Gestore Tenant',
      viewer: 'Visualizzatore',
      person_manager: 'Gestore Persone',
      card_container_manager: 'Gestore Contenitori Schede',
      registry_manager: 'Gestore Anagrafiche',
      project_manager: 'Gestore Progetti',
      person_compiler: 'Compilatore Persone'
    },
    home: {
      hello: 'Ciao',
      profile: 'Il tuo profilo',
      last_cards: 'Ultime schede caricate',
      platform_configuration: 'Configurazione della piattaforma',
      platform_upload: `Dimensione massima dell'upload`,
      platform_file_size: `Dimensione massima dei singoli file`,
      platform_file_number: `Numero massimo di file simultanei da caricare`,
      dashboard: {
        models_title: 'Tipologie di schede',
        archives_title: 'Fondi',
        registries_title: 'Anagrafiche',
        counts_global_title: 'Statistiche globali'
      }
    },
    views: {
      dashboard: {
        title: 'Benvenuto!',
        subtitle: 'Dashboard',
        single: '',
        fields: {}
      },
      unauthorized: {
        title: 'Non puoi accedere a questa pagina',
        subtitle: 'Non hai i permessi per accedere a questa sezione',
        single: '',
        fields: {}
      },
      login: {
        title: 'Accedi',
        subtitle: 'Inserisci le tue credenziali',
        single: '',
        fields: {
          name: 'Nome',
          surname: 'Cognome',
          email: 'Email',
          password: 'Password',
          confirm_password: 'Ripeti password',
          institution: 'Ente'
        },
        missing_email: 'Inserisci la tua email',
        reset_password: `E' stata inviata una email con un link che ti consente di reimpostare la tua password.`
      },
      register: {
        title: 'Registrazione',
        lost_password: 'Password dimenticata',
        request:'La richiesta di registrazione è avvenuta con successo.<br/>Riceverai una mail con un link per verificare il tuo account. Clicca sul link presente per poter accedere alla piattaforma.',
        resend_verification: `E' stata inviata una nuova mail di verifica all'indirizzo email indicato.`,
        email_already_verified: 'La tua email è stata già verificata. Clicca sul seguente pulsante per effettuare il login',
        email_verified: 'La tua email è stata verificata. Clicca sul seguente pulsante per effettuare il login.',
        email_to_verify: 'La tua email deve essere verificata.'
      },
      lostpassword: {
        title: 'Reimposta la password',
        missing_data: 'Per reimpostare la password vai alla pagina di login e clicca su "Password dimenticata"',
        password_changed: 'La password è stata aggiornata con successo. Adesso puoi effettuare login.'
      },
      projects: {
        title: 'Progetti',
        subtitle: 'Gestisci i progetti',
        single: 'Progetto',
        fields: {
          code: 'Codice',
          name: 'Nome',
          introduction: 'Introduzione',
          location: 'Luogo',
          cost: 'Costo (in €)',
          card_containers_count: 'Contenitori Schede',
          card_containers: 'Contenitori Schede',
          tenants: 'Tenant',
          users: 'Utenti'
        }
      },
      users: {
        title: 'Utenti',
        subtitle: 'Gestisci gli utenti',
        single: 'Utente',
        fields: {
          name: 'Nome',
          surname: 'Cognome',
          email: 'Email',
          roles: 'Ruoli',
          password: 'Password',
          confirm_password: 'Ripeti password',
          cards_count: 'Schede',
          email_verified_at: 'Verificato il',
          institution: 'Ente',
          favourites: 'Preferiti',
          verified: 'Verificato',
          created_at: 'Utente da',
          associations: 'Associazioni',
          archives: 'Fondi',
          projects: 'Progetti',
          registries: 'Anagrafiche',
          tenants: 'Tenant'
        },
        profile: {
          title: 'Il tuo profilo'
        }
      },
      cards: {
        title: 'Schede',
        subtitle: 'Gestisci le schede',
        single: 'Scheda',
        created_by: 'Creato da',
        created_at: 'Creato il',
        updated_at: 'Ultimo aggiornamento',
        fields: {
          name: 'Nome',
          code: 'Codice',
          archive_id: 'Fondo',
          archive: 'Fondo',
          user_id: 'Schedatore',
          user: 'Schedatore',
          is_visible: 'Visibile',
          is_published: 'Pubblico',
          location: 'Luogo GIS',
          location_id: 'Luogo GIS',
          tags: 'Tag',
          card_separator: 'Dati scheda base',
          lemma_separator: 'Lemmi associati alla scheda',
          references: 'Riferimenti bibliografici',
          reference_sources: 'Fonti'
        },
        files: {
          types: {
            document: 'Documentazione',
            raster: 'Raster',
            photo: 'Fotografia',
            image: 'Immagine',
            threed_model: 'Modello 3D', 
            threed_texture: 'Texture 3D', 
            spheric_picture: 'Foto a 360°'
          },
          fields: {
            description: 'Descrizione file',
            license: `Licenza d'uso`,
            reference: 'Referenze',
            crs: 'Sistema di riferimento',
            ne_lat: 'Latitudine NE',
            ne_lng: 'Longitudine NE',
            sw_lat: 'Latitudine SO',
            sw_lng: 'Longitudine SO',
            file_id: 'File associato',
            elevation: 'Quota',
            file_select: 'Seleziona un file da associare'
          }
        },
        locations: {
          fields: {
            type: 'Tipologia',
            description: 'Descrizione'
          }
        },
        persons: {
          separator: 'Persone',
          name: 'Persone della scheda',
          title: 'Persone associate alla scheda',
          add_new: 'Associa una nuova persona alla scheda',
          fields: {
            card_person_type_id: 'Tipologia di relazione alla persona',
            description: 'Descrizione',
            tags: 'Tag'
          },
          tip: 'Associa persone alla scheda'
        },
        references: {
          separator: 'Riferimenti bibliografici',
          name: 'Bibliografia della scheda',
          title: 'Riferimenti bibliografici associati alla scheda',
          add_new: 'Associa un nuovo riferimento bibliografico alla scheda',
          fields: {
            citation: 'Citazione specifica',
          },
          tip: 'Associa riferimenti bibliografici alla scheda'
        }
      },
      archives: {
        title: 'Fondi',
        subtitle: 'Gestisci i fondi',
        single: 'Fondo',
        fields: {
          name: 'Nome',
          code: 'Codice',
          description: 'Descrizione',
          cards_count: 'Schede',
          users: 'Utenti',
          tenants: 'Tenant'
        },
        select: `Seleziona un'archivio`
      },
      tenants: {
        title: 'Tenant',
        subtitle: 'Gestisci i tenant della piattaforma',
        single: 'Tenant',
        fields: {
          name: 'Nome',
          code: 'Codice',
          namespace: 'App Namespace',
          url_production: 'Website URL di produzione',
          url_staging: 'Website URL di staging',
          url_development: 'Website URL di sviluppo',
          url_card: 'Base URL della pagina della scheda',
          email: 'Email del mittente per le notitiche utente',
          logo: 'Logo',
          color: 'Colore',
          users_count: 'Utenti',
          archives_count: 'Fondi',
          users: 'Utenti',
          archives: 'Fondi',
          registries: 'Anagrafiche',
          projects: 'Progetti',
          projects_count: 'Progetti'
        },
        select: `Seleziona un tenant`
      },
      locations: {
        title: 'Luoghi GIS',
        subtitle: 'Gestisci i luoghi GIS da associare alle schede',
        single: 'Luogo GIS',
        fields: {
          name: 'Nome',
          address: 'Indirizzo',
          location: 'Posizione',
          accuracy: 'Accuratezza',
          geometries_separator: 'Campi Geometrie GIS',
          location_latitude: 'Latitudine',
          location_longitude: 'Longitudine',
          polygon: 'Poligono',
          pointz: 'PointZ',
          multipointz: 'MultiPointZ',
          polygonz: 'PolygonZ',
          linestringz: 'LineStringZ',
          geometries: 'Geometrie',
          classification_id: 'Classificazione',
          card_id: 'Scheda'
        },
        add: 'Aggiungi luogo GIS'
      },
      classifications: {
        title: 'Classificazioni',
        subtitle: `Gestisci le classificazioni per i luoghi e geometrie GIS`,
        single: 'Classificazione',
        fields: {
          name: 'Nome',
          type: 'Tipologia',
          color: 'Colore',
          description: 'Descrizione',
          location: 'Luogo GIS',
          location_id: 'Luogo GIS',
          locations_count: 'Luoghi GIS'
        }
      },
      tags: {
        title: 'Tag',
        subtitle: 'Gestisci i tag da associare alle schede',
        single: 'Tag',
        fields: {
          name: 'Nome',
          cards_count: 'Schede'
        },
        select: `Seleziona un tag`
      },
      mapviewer: {
        title: 'Map Viewer',
        subtitle: 'Map Viewer for georeferenced raster images',
        single: 'Map Viewer'
      },
      medias: {
        title: 'Media',
        subtitle: 'Gestisci i media',
        single: 'Media',
        fields: {
          file: 'File',
          original: 'Originale',
          card: 'Scheda',
          code: 'Codice Scheda',
          description: 'Descrizione',
          license: `Licenza d'uso`,
          reference: 'Referenze',
          format: 'Formato',
          format_select: 'Seleziona un formato',
          is_active: 'Attivo',
          is_cover: 'Cover',
          is_image: 'Immagine',
          fileable: 'Scheda associata',
          fileable_code: 'Codice scheda associata',
          fileable_name: 'Nome scheda associata',
        },
      },
      logs: {
        title: 'Logs',
        subtitle: 'API Platform Logs',
        single: 'Log',
        fields: {
          causer: 'Causer',
          status: 'Status',
          event: 'Event',
          route: 'Route',
          created_at: 'Date'
        }
      },
      persons: {
        title: 'Persone',
        subtitle: 'Gestisci le Persone',
        single: 'Persona',
        fields: {
          code: 'Codice',
          name: 'Nome scelto',
          pre_name: 'Prenome',
          first_name: 'Nome',
          middle_name: 'Secondo nome',
          registries: 'Anagrafiche',
          last_name: 'Cognome',
          title: 'Titolo',
          nick_name: 'Soprannome',
          initials: 'Iniziali',
          sex: 'Sesso',
          birth_date: 'Data di nascita',
          birth_place: 'Luogo di nascita',
          death_date: 'Data di morte',
          death_place: 'Luogo di morte',
          from_date: 'Data di nascita testuale',
          to_date: 'Data di morte testuale',
          biography: 'Biografia',
          notes: 'Note',
          origin: 'Origine',
          nationality: 'Nazionalità',
          website: 'URL Sito Web',
          person_type_id: 'Tipologia di Persona',
          person_type: 'Tipologia di Persona',
          user_id: 'Compilatore',
          user: 'Compilatore',
          registry: 'Anagrafica',
          registry_id: 'Anagrafica',
          is_visible: 'Visibile',
          is_published: 'Pubblico',
          card_separator: 'Dati base della scheda',
          person_separator: 'Dati della Persona',
          qualifications: 'Qualifiche',
          location_person_type: 'Tipologia di Luoghi Persona',
          tags: 'Tag',
          certifications_separator: 'Voci di certificatione della persona'
        },
        person_certifications: {
          title: 'Voci di certificazione / gestione dati',
          add: 'Aggiungi un nuova voce di certificazione/gestione dati',
          fields: {
            name: 'Nome',
            responsible: 'Responsabile',
            year: 'Anno',
            note: 'Note'
          }
        },
        locations: {
          title: 'Luoghi GIS legati alla Persona',
          pivot: 'Informazioni di dettaglio',
          fields: {
            location_person_type: 'Tipo di luogo',
            description: 'Descrizione',
            start_date: 'Data inizio',
            end_date: 'Data fine',
            from_date: 'Data inizio testuale',
            to_date: 'Data fine testuale',
          }
        },
        qualifications: {
          title: 'Qualifiche associate',
          name: 'Qualifiche della Persona',
          add_new: 'Aggiungi qualifica',
          fields: {
            description: 'Descrizione'
          }
        },
        references: {
          separator: 'Riferimenti bibliografici',
          name: 'Bibliografia della persona',
          title: 'Riferimenti bibliografici associati alla persona',
          add_new: 'Associa un nuovo riferimento bibliografico alla persona',
          fields: {
            citation: 'Citazione specifica',
          },
          tip: 'Associa riferimenti bibliografici alla persona'
        }
      },
      person_types: {
        title: 'Tipologie di Persone',
        subtitle: 'Gestisci le Tipologie di Persone',
        single: 'Tipologia di Persona',
        fields: {
          code: 'Codice',
          name: 'Nome',
          description: 'Descrizione',
        }
      },
      qualifications: {
        title: 'Qualifiche di persone',
        subtitle: 'Gestisci le Qualifiche di Persone',
        single: 'Qualifica di Persona',
        fields: {
          code: 'Codice',
          name: 'Nome',
          description: 'Descrizione',
        }
      },
      location_person_types: {
        title: 'Tipologie di Luoghi di Persona',
        subtitle: 'Gestisci le Tipologie di Luoghi di Persona',
        single: 'Tipologia di Luogo di Persona',
        fields: {
          code: 'Codice',
          name: 'Nome',
          description: 'Descrizione',
        }
      },
      card_person_types: {
        title: 'Tipologie di Schede legate alla Persona',
        subtitle: 'Gestisci le Tipologie di Schede legate alla Persona',
        single: 'Tipologia di Scheda legata alla Persona',
        fields: {
          code: 'Codice',
          name: 'Nome',
          description: 'Descrizione',
        }
      },
      registries: {
        title: 'Anagrafiche',
        subtitle: `Gestisci l'Anagrafica delle Persone`,
        single: 'Anagrafica',
        fields: {
          name: 'Nome',
          code: 'Codice',
          description: 'Descrizione',
          persons_count: 'Persone',
          users: 'Utenti',
          tenants: 'Tenant'
        },
        select: `Seleziona un'anagrafica`
      },
      card_containers: {
        title: 'Contenitori di Schede',
        subtitle: `Gestisci i vari raggruppamenti di Schede`,
        single: 'Contenitore',
        fields: {
          name: 'Nome',
          code: 'Codice',
          is_visible: 'Visibile',
          is_published: 'Pubblico',
          description: 'Descrizione',
          user_id: 'Compilatore',
          card_container_type_id: 'Tipo di Contenitore',
          users: 'Utenti',
          tenants: 'Tenant',
          cards_count: 'Schede',
          projects: 'Progetti',
          tags: 'Tag',
          show_track: 'Mostra tracciato',
          order_by_date: 'Ordina per data'
        },
        cards: {
          separator: 'Gestisci le schede del Contenitore',
          name: 'Schede del Contenitore',
          add_new: 'Aggiungi una Scheda al Contenitore',
          tip: 'Aggiungi le Schede che fanno parte della raccolta. Dopo averle aggiunte trascina il cursore presente su ognuna di esse per ordinarle.',
          fields: {
            start_date: 'Data inizio',
            end_date: 'Data fine',
            description: 'Descrizione'
          }
        },
        select: `Seleziona un contenitore`,
        uploader_text: `Importa un'immagine per il Contenitore`
      },
      card_container_types: {
        title: 'Tipologie di Contenitori di Schede',
        subtitle: 'Gestisci le Tipologie di Contenitori di Schede',
        single: 'Tipologia di Contenitore di Schede',
        fields: {
          code: 'Codice',
          name: 'Nome',
          description: 'Descrizione',
        }
      },
      references: {
        title: 'Riferimenti bibliografici',
        subtitle: 'Gestisci i riferimenti bibliografici',
        single: 'Riferimento bibliografico',
        fields: {
          citation: 'Citazione',
          title: 'Titolo',
          author: 'Autore',
          year: 'Anno',
          cards: 'Schede',
          persons: 'Persone',
          is_published: 'Pubblicato',
          cards_count: 'Schede',
          persons_count: 'Persone',
          reference_sources: 'Fonti',
          reference_sources_count: 'Fonti'
        }
      },
      reference_sources: {
        title: 'Fonti di riferimenti bibliografici',
        subtitle: 'Gestisci le fonti',
        single: 'Fonte di riferimento bibliografico',
        fields: {
          name: 'Nome',
          references: 'Riferimenti bibliografici',
          is_published: 'Pubblicato',
          color: 'Colore',
          references_count: 'Riferimenti bibliografici'
        }
      },
      communications: {
        title: 'Comunicazioni',
        subtitle: 'Invia comunicazioni agli utenti della piattaforma',
        single: 'Comunicazione',
        fields: {
          subject: 'Oggetto',
          body: 'Testo del messaggio',
          users: 'Utenti',
          roles: 'Ruoli',
          tenants: 'Tenant',
          archives: 'Fondi',
          registries: 'Anagrafiche',
          projects: 'Progetti',
        },
      },
      roles: {
        select: 'Seleziona un ruolo'
      }
    }
  }
}

// All messages of plugins is like: $t('plugins.PLUGIN_NAME.*')
for(const locale in messages){
  messages[locale].plugins = []
  for(const plugin in plugins.messages){
    messages[locale].plugins[plugin] = plugins.messages[plugin][locale]
  }
}

// Create VueI18n instance with options
export default new VueI18n({
  locale: 'it', // set locale
  messages, // set locale messages
})