<template>
  <div :class="'rounded-md border-gray-300 border ' + classes">
    <div v-if="editor">
      <div v-if="label">{{ label }}</div>
      <div :class="'bg-gray-200 rounded-sm p-2 text-xs text-gray-900 '">
        <button v-if="!disable" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }" class="font-bold bg-gray-400 px-1 rounded-sm mr-1">b</button>
        <button v-if="!disable" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }" class="italic bg-gray-400 px-1 rounded-sm mr-1">i</button>
        <!--<button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">strike</button>-->
        <!--<button @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">code</button>-->
        <!--<button @click="editor.chain().focus().unsetAllMarks().run()">clear marks</button>-->
        <!--<button @click="editor.chain().focus().clearNodes().run()">clear nodes</button>-->
        <!--<button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">paragraph</button>>-->
        <!--<button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">h1</button>-->
        <!--<button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">h2</button>-->
        <!--<button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">h3</button>-->
        <!--<button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">h4</button>-->
        <!--<button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">h5</button>-->
        <!--<button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">h6</button>-->
        <button v-if="!disable" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }" class="bg-gray-400 px-1 rounded-sm mr-1">{{ $t('actions.editor.list') }}</button>
        <!--<button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">ordered list</button>-->
        <!--<button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">code block</button>-->
        <!--<button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">blockquote</button>-->
        <!--<button @click="editor.chain().focus().setHorizontalRule().run()">horizontal rule</button>-->
        <!--<button @click="editor.chain().focus().setHardBreak().run()">hard break</button>-->
        <!--<button @click="editor.chain().focus().undo().run()">undo</button>-->
        <!--<button @click="editor.chain().focus().redo().run()">redo</button>-->
      </div>
    </div>
    <div v-if="disable" :class="'p-3 ' + classesText">
      <p v-html="value" />
    </div>
    <editor-content v-else :editor="editor" :class="'p-3 ' + classesText" />
  </div>
</template>
<script>
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import StarterKit from '@tiptap/starter-kit'

  export default {
    name: 'TextEditor',
    components: {
      EditorContent,
    },
    props: {
      value: {
        required: true,
      },
      classes: {
        default: null
      },
      classesText: {
        default: null
      },
      disable: {
        default: false
      },
      label: {
        default: null
      }
    },
    data() {
      return {
        disabled: false,
        editor: null
      }
    },
    watch: {
      value(value) {
        const isSame = this.editor.getHTML() === value
        if (isSame) return
        this.editor.commands.setContent(value, false)
      },
    },
    mounted() {
      this.editor = new Editor({
        content: this.value,
        extensions: [
          StarterKit,
        ],
        injectCSS: false,
        editable: !this.disabled,
        onUpdate: () => {
          this.$emit('input', this.editor.getHTML())
        },
      })
    },
    beforeDestroy() {
      this.editor.destroy()
    },
  }
</script>
<style lang="less">
/* Scoped to the editor */
.ProseMirror {
  height: 200px;
  outline: none;
  overflow-y: scroll;
  ul {
    list-style-type: circle;
    padding-left: 20px;
  }
}
</style>