var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scan_cards"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.ereditaculturali.views.scan_cards.single'))+" ")]},proxy:true},{key:"main",fn:function(){return [(_vm.canLoad)?_c('CrudEntity',{attrs:{"model":_vm.model,"id":_vm.id,"mode":_vm.crud_mode,"entity":_vm.entity,"is-extended-card":true,"extends":"card","has-location":true,"check-owner":true,"fields":[

          // Base Card fields
          {text: _vm.$t('views.cards.fields.card_separator'), type: 'separator'},
          {name: _vm.$t('views.cards.fields.code'), field: 'code', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.name'), field: 'name', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.ereditaculturali.tips.is_visible')},
          {name: _vm.$t('views.cards.fields.is_published'), field: 'is_published', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.ereditaculturali.tips.is_published')},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.archive'), field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
          {name: _vm.$t('views.cards.fields.user_id'), field: 'user_id', related: 'card', type: 'select', model: 'users', labels: ['name', 'surname'], select: _vm.select_user },
          {name: _vm.$t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', labels: ['cards_count'],taggable: true},
          
          {text: _vm.$t('views.cards.persons.separator'), type: 'separator'},
          {
            field: 'persons', type: 'relations-manager', related: 'card', model: 'cards', relatedModel: 'persons', label: 'name', span: '4', taggable: false, ordering: true,
            pivots: [ 
              {name: _vm.$t('views.cards.persons.fields.card_person_type_id'), field: 'card_person_type_id', model: 'card_person_types',  label: 'name', type: 'select' },
              {name: _vm.$t('views.cards.persons.fields.description'), field: 'description', type: 'text-editor'}
            ],
            name: _vm.$t('views.cards.persons.name'), 
            title: _vm.$t('views.cards.persons.title'),
            addNewText: _vm.$t('views.cards.persons.add_new'),
            tip: _vm.$t('views.cards.persons.tip'),
            labeled: true
          },

          // Main fields
          
          // CD fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.cd_separator'), type: 'separator', hide: true, hiddenFields: ['tsk', 'lir', 'nctr', 'nctn', 'esc', 'ecp', 'acce', 'accw', 'accr', 'accs']},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.tsk'), field: 'tsk', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.lir'), field: 'lir', type: 'select', options: _vm.lists.vocabularies.catalog_levels, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.nctr'), field: 'nctr', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.nctn'), field: 'nctn', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.esc'), field: 'esc', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ecp'), field: 'ecp', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.acce'), field: 'acce', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.accw'), field: 'accw', type: 'repeating-text'}, // ripetibile con |
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.accr'), field: 'accr', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.accs'), field: 'accs', type: 'text-editor', span: '2'},

          // OG fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.og_separator'), type: 'separator', hide: true, hiddenFields: ['ctb', 'set', 'tbc', 'ctg', 'ogd', 'ogt', 'ogn']},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ctb'), field: 'ctb', type: 'select', options: _vm.lists.vocabularies.ctb, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.set'), field: 'set', type: 'select', options: _vm.lists.vocabularies.set, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.tbc'), field: 'tbc', type: 'select', options: _vm.lists.vocabularies.tbc, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ctg'), field: 'ctg', type: 'select', options: _vm.lists.vocabularies.ctg, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ogd'), field: 'ogd', type: 'select', options: _vm.lists.vocabularies.ogd, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ogt'), field: 'ogt', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ogn'), field: 'ogn', type: 'repeating-text'}, // ripetibile con |

          // RV fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.rv_separator'), type: 'separator', hide: true, hiddenFields: ['related_card_id']},
          // setup related Card + pivot
          { // Card
            field: 'related_card_id', type: 'lemma', model: 'relateds', label: 'code', span: '2', taggable: false,
            pivots: [ 
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.rser'), field: 'type', type: 'select', options: _vm.lists.vocabularies.relationships, label: 'name', valueField: 'value'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.rses'), field: 'direction', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.rez'), field: 'description', type: 'text-editor'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.notes'), field: 'notes', type: 'text'}
            ],
            name: _vm.$t('plugins.ereditaculturali.views.scan_cards.related_card.name'), 
            title: _vm.$t('plugins.ereditaculturali.views.scan_cards.related_card.title'),
            addNewText: _vm.$t('plugins.ereditaculturali.views.scan_cards.related_card.add_new'),
            tip: _vm.$t('plugins.ereditaculturali.views.scan_cards.related_card.tip'),
            labeled: true
          },

          // LC fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.lc_separator'), type: 'separator', hide: true, hiddenFields: ['lcs','lcr','lcp','lcc','lci','lcv','plv','pve','pvz','ldct','ldcn','ldck','ldcm','ldcg','ldcz','ldcs']},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.lcs'), field: 'lcs', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.lcr'), field: 'lcr', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.lcp'), field: 'lcp', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.lcc'), field: 'lcc', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.lci'), field: 'lci', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.lcv'), field: 'lcv', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.plv'), field: 'plv', type: 'repeating-text'}, // ripetibile con |
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.pvz'), field: 'pvz', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.pve'), field: 'pve', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ldct'), field: 'ldct', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ldcn'), field: 'ldcn', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ldck'), field: 'ldck', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ldcm'), field: 'ldcm', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ldcg'), field: 'ldcg', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ldcz'), field: 'ldcz', type: 'text-editor'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ldcs'), field: 'ldcs', type: 'text-editor'},

          // GE fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ge_separator'), type: 'separator', hide: true, hiddenFields: ['gel','get','gep','gec','gecx','gecy','aff','gpbb','gpbt','gpbu']},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.gel'), field: 'gel', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.get'), field: 'get', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.gep'), field: 'gep', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.gec'), field: 'gec', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.gecx'), field: 'gecx', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.gecy'), field: 'gecy', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.aff'), field: 'aff', type: 'select', options: _vm.lists.vocabularies.accuracy, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.gpbb'), field: 'gpbb', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.gpbt'), field: 'gpbt', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.gpbu'), field: 'gpbu', type: 'text'},

          // DT fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.dt_separator'), type: 'separator', hide: true, hiddenFields: ['dtzg','dtzs','dtsi','dtsv','dtsf','dtsl','dtt','atb','scan_authority_id']},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.dtzg'), field: 'dtzg', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.dtzs'), field: 'dtzs', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.dtsi'), field: 'dtsi', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.dtsv'), field: 'dtsv', type: 'select', options: _vm.lists.vocabularies.validity, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.dtsf'), field: 'dtsf', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.dtsl'), field: 'dtsl', type: 'select', options: _vm.lists.vocabularies.validity, label: 'name', valueField: 'value'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.dtt'), field: 'dtt', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.atb'), field: 'atb', type: 'text-editor', span: '2'},
          { // ScanAuthority
            field: 'scan_authority_id', type: 'related', model: 'scan_authorities', label: 'name', span: '4',
            fields: [ 
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_authorities.fields.autn'),      field: 'autn', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_authorities.fields.auta'),      field: 'auta', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_authorities.fields.autz'),      field: 'autz', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_authorities.fields.autj'),      field: 'autj', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_authorities.fields.auth'),      field: 'auth', type: 'text'},
            ],
            name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_authorities.title'),
            add: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_authorities.add')
          },

          // DA fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.da_separator'), type: 'separator', hide: true, hiddenFields: ['des','aid','res','nsc','scan_historical_id','elr']},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.des'), field: 'des', type: 'text-editor', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.aid'), field: 'aid', type: 'text-editor', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.res'), field: 'res', type: 'text-editor', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.nsc'), field: 'nsc', type: 'text-editor', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.elr'), field: 'elr', type: 'text-editor', span: '2'},
          { // ScanHistorical
            field: 'scan_historical_id', type: 'related', model: 'scan_historicals', label: 'name', span: '4',
            fields: [ 
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_historicals.fields.dtns'),      field: 'dtns', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_historicals.fields.dtr'),       field: 'dtr', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_historicals.fields.dtg'),       field: 'dtg', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_historicals.fields.dtnn'),      field: 'dtnn', type: 'text-editor', span: '2'},
            ],
            name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_historicals.title'),
            add: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_historicals.add')
          },

          // MT fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.mt_separator'), type: 'separator', hide: true, hiddenFields: ['nvc','scan_measure_id','mtc','cdg','sta','stc','rst','nvc','scan_measures_id']},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.mtc'), field: 'mtc', type: 'text', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.cdg'), field: 'cdg', type: 'text', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.sta'), field: 'sta', type: 'text', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.stc'), field: 'stc', type: 'text-editor', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.rst'), field: 'rst', type: 'text-editor', span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.nvc'), field: 'nvc', type: 'text-editor', span: '2'},
          { // ScanTechnicalData
            field: 'scan_measures_id', type: 'related', model: 'scan_measures', label: 'name', span: '4',
            fields: [ 
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_measures.fields.misz'),     field: 'misz', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_measures.fields.misu'),     field: 'misu', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_measures.fields.mism'),     field: 'mism', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_measures.fields.misv'),     field: 'misv', type: 'text-editor', span: '2'},
            ],
            name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_measures.title'),
            add: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_measures.add')
          },

          // DO fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.do_separator'), type: 'separator', hide: true, hiddenFields: ['scan_documentation_id','bib','references']},
          { // ScanDocumentation
            field: 'scan_documentation_id', type: 'related', model: 'scan_documentations', label: 'name', span: '4',
            fields: [ 
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcmn'),     field: 'dcmn', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcmp'),     field: 'dcmp', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcmm'),     field: 'dcmm', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcma'),     field: 'dcma', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcmr'),     field: 'dcmr', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcme'),     field: 'dcme', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcmc'),     field: 'dcmc', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcmw'),     field: 'dcmw', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcmk'),     field: 'dcmk', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.dcmt'),     field: 'dcmt', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.files'),    field: 'files', type: 'multi-select', model: 'files', label: 'id', labels: ['description', 'id'], span: '2' },
            ],
            name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.title'),
            add: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.add')
          },
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_documentations.fields.bib'),      field: 'bib', type: 'repeating-text', span: '4'},
          // {name: $t('views.cards.fields.references'), field: 'references', related: 'card', model: 'references', type: 'multi-select', label: 'citation', labels:['citation'], taggable: true, span: '2'},
          {
            field: 'references', type: 'relations-manager', related: 'card', model: 'cards', relatedModel: 'references', relatedSearchField: 'title', relatedLabel: 'title', labels: ['title', 'pivot.citation'], span: '4',
            pivots: [ 
              {name: _vm.$t('views.cards.references.fields.citation'), field: 'citation', type: 'text' }
            ],
            name: _vm.$t('views.cards.references.name'), 
            title: _vm.$t('views.cards.references.title'),
            addNewText: _vm.$t('views.cards.references.add_new'),
            tip: _vm.$t('views.cards.references.tip'),
            labeled: true
          },

          // CM fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.cm_separator'), type: 'separator', hide: true, hiddenFields: ['scan_certification_id']},
          { // ScanCertification
            field: 'scan_certification_id', type: 'related', model: 'scan_certifications', label: 'name', span: '4',
            fields: [ 
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.cmr'),     field: 'cmr', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.cma'),     field: 'cma', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.adp'),     field: 'adp', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.oss'),     field: 'oss', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.cmm'),     field: 'cmm', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.cmmn'),     field: 'cmmn', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.cmmd'),     field: 'cmmd', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.cmmc'),     field: 'cmmc', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.fields.cmmf'),     field: 'cmmf', type: 'text'},
            ],
            name: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.title'),
            add: _vm.$t('plugins.ereditaculturali.views.scan_cards.scan_certifications.add')
          },

          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.other_fields'), type: 'separator', hide: true, hiddenFields: ['cam','ro']},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.cam'), field: 'cam', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.scan_cards.fields.ro'), field: 'ro', type: 'repeating-text', span: '4'},

          // Location fields
          {text: _vm.$t('plugins.ereditaculturali.views.scan_cards.location.title'), type: 'separator'},

          // {name: $t('views.locations.fields.name'),         related: 'card', subrelated: 'location', field: 'name', type: 'text', span: '2'},
          // {name: $t('views.locations.fields.address'),      related: 'card', subrelated: 'location', field: 'address', type: 'text', span: '2'},
          // {name: $t('views.locations.fields.accuracy'),     related: 'card', subrelated: 'location', field: 'accuracy', type: 'text', span: '2'},
          // {name: $t('views.locations.fields.classification_id'), related: 'card', subrelated: 'location', field: 'classification_id', span: '2', type: 'select', model: 'classifications', label: 'name' },
          // {name: $t('views.locations.fields.location'),     related: 'card', subrelated: 'location', field: 'location', type: 'location', span: '2'},
          // {name: $t('views.locations.fields.polygonz'),      related: 'card', subrelated: 'location', field: 'polygonz', type: 'geometry', geometry: 'polygonz', mode: 'map', span: '4'},

          {name: _vm.$t('components.locations_manager.title'), field: 'locations', related: 'card', model: 'cards', type: 'location-manager', span: '4',
            fields: [ 
              {name: _vm.$t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.srid'), field: 'srid', type: 'text', span: '1'},
              {name: _vm.$t('views.locations.fields.classification_id'), field: 'classification_id', span: '2', type: 'select', model: 'classifications', label: 'name' },
              {name: _vm.$t('views.locations.fields.location'), field: 'location', type: 'location', span: '4'},
              {name: _vm.$t('views.locations.fields.polygonz'), field: 'polygonz', type: 'geometry', geometry: 'polygonz', mode: 'map', span: '4'},
            ],
            pivots: [ 
              {name: _vm.$t('views.cards.locations.fields.type'), field: 'type', type: 'text', span: '2'},
              {name: _vm.$t('views.cards.locations.fields.description'), field: 'description', type: 'text', span: '2'},
            ],
            pivotTitle: _vm.$t('views.persons.locations.pivot'),
            wmsLayers: [
              {
                name: 'Carta Geometrica della Toscana (G. Inghirami - 1830)',
                visible: true,
                format: 'image/png',
                url: 'http://www502.regione.toscana.it/wmsraster/com.rt.wms.RTmap/wms?map=wmscastore&map_resolution=91&language=ita',
                layer: 'r_toscan_cst_inghirami',
                transparent: true,
                attribution: 'WMS Castore, Regione Toscana'
              },
              {
                name: 'Catasto Storico Toscana - Continuo territoriale dei Fogli',
                visible: true,
                format: 'image/png',
                url: 'http://www502.regione.toscana.it/wmsraster/com.rt.wms.RTmap/wms?map=wmscastore&map_resolution=91&language=ita',
                layer: 'r_toscan_cst_fogli',
                transparent: true,
                attribution: 'WMS Castore, Regione Toscana'
              }
            ]
          },

          // { // Locations
          //   field: 'location_id', type: 'related', model: 'locations', related: 'card', subrelated: 'location', label: 'name', span: '4',
          //   fields: [ 
          //     {name: $t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
          //     {name: $t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
          //     {name: $t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
          //     {name: $t('views.locations.fields.classification_id'), field: 'classification_id', span: '2', type: 'select', model: 'classifications', label: 'name' },
          //     {name: $t('views.locations.fields.location'), field: 'location', type: 'location', span: '4'},
          //     {name: $t('views.locations.fields.polygonz'), field: 'polygonz', type: 'geometry', geometry: 'polygonz', mode: 'map', span: '4'},
          //   ],
          //   name: $t('views.locations.title'),
          //   add: $t('views.locations.add')
          // },
          
        ]}}):_vm._e(),(_vm.id)?_c('div',{staticClass:"mt-2"},[(_vm.canLoad)?_c('FileManager',{attrs:{"mode":_vm.crud_mode,"model-id":parseInt(_vm.id),"model":_vm.model,"entity-data":_vm.entity,"related":true,"uploaderText":_vm.$t('plugins.ereditaculturali.views.scan_cards.uploader_text'),"file-types-allowed":[
            {name: 'PNG', type: 'image/png'},
            {name: 'JPG', type: 'image/jpeg'},
            {name: 'TIFF', type: 'image/tiff'},
            {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
            {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {name: 'OBJ', type: 'text/plain'},
            {name: 'PDF', type: 'application/pdf'}
          ],"file-model-types":[
            {type: 'document', name: _vm.$t('views.cards.files.types.document'),  fields: ['reference', 'license', 'description']},
            {type: 'raster',   name: _vm.$t('views.cards.files.types.raster'),    fields: ['reference', 'license', 'description', 'ne_lat', 'ne_lng', 'sw_lat', 'sw_lng', 'elevation']},
            {type: 'image', name: _vm.$t('views.cards.files.types.image'),  fields: ['reference', 'license', 'description']},
            {type: 'photo', name: _vm.$t('views.cards.files.types.photo'),  fields: ['reference', 'license', 'description']},
            {type: 'threed_model', name: _vm.$t('views.cards.files.types.threed_model'), fields: ['reference', 'license', 'description', 'file_id']},
            {type: 'threed_texture', name: _vm.$t('views.cards.files.types.threed_texture'), fields: ['reference', 'license', 'description', 'file_id']},
            {type: 'spheric_picture', name: _vm.$t('views.cards.files.types.spheric_picture'), fields: ['reference', 'license', 'description']},
          ],"file-model-fields":[
            {name: _vm.$t('views.cards.files.fields.reference'), field: 'reference', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.license'), field: 'license', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.description'), field: 'description', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.crs'), field: 'crs', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lat'), field: 'ne_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lng'), field: 'ne_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lat'), field: 'sw_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lng'), field: 'sw_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.file_id'), field: 'file_id', type: 'select', related: 'files', label: 'id', placeholder: _vm.$t('views.cards.files.fields.file_select')},
            {name: _vm.$t('views.cards.files.fields.elevation'), field: 'elevation', type: 'text'},
          ]}}):_vm._e()],1):_vm._e(),(_vm.canLoad)?_c('EntityInfo',{attrs:{"entity":_vm.entity}}):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }