module.exports = {
  host: process.env.HOST || 'localhost',
  open: true,
  port: process.env.PORT || 8080,
  apiRoot: process.env.VUE_APP_API_ROOT || 'http://localhost:8000',
  base_url: {
    cards: 'card/',
    persons: 'person/',
    card_containers: 'card-container/'
  },
  menuItems: [
    {route: '/home', permissions: ['all'], label: 'menu.home', icon: 'chart-pie'},
    {route: 'separator',            permissions: ['manage_archives', 'manage_cards'], label: 'menu.cards', icon: 'file', items: []},
    {route: 'separator', permissions: ['manage_projects', 'manage_card_containers', 'manage_persons', 'compile_persons'], label: 'menu.persons_separator', icon: 'user-tie',
      items: [
        {route: '/persons', permissions: ['manage_registries', 'manage_persons', 'manage_users', 'compile_persons'], label: 'menu.persons', icon: 'user-tie'},
        {route: '/registries', permissions: ['manage_registries'], label: 'menu.registries', icon: 'address-book'},
        {route: '/person_types', permissions: ['manage_users'], label: 'menu.person_types', icon: 'user-tag'},
        {route: '/qualifications', permissions: ['manage_users'], label: 'menu.qualifications', icon: 'user-graduate'},
        {route: '/location_person_types', permissions: ['manage_users'], label: 'menu.location_person_types', icon: 'house-user'},
        {route: '/card_person_types', permissions: ['manage_users'], label: 'menu.card_person_types', icon: 'user-ninja'},
      ]
    },
    {route: 'separator', permissions: ['manage_projects', 'manage_card_containers'],label: 'menu.card_container_separator', icon: 'layer-group',
        items: [
          {route: '/card_containers', permissions: ['manage_projects', 'manage_card_containers'], label: 'menu.card_containers', icon: 'layer-group'},
          {route: '/card_container_types',  permissions: ['manage_users'], label: 'menu.card_container_types', icon: 'bookmark'},
        ]
    },
    {route: 'separator',  permissions: ['manage_cards'],  label: 'menu.taxonomies', icon: 'tags',
        items: [
          {route: '/tags',  permissions: ['manage_cards'],  label: 'menu.tags', icon: 'tags'},
          {route: '/classifications',  permissions: ['manage_cards'], label: 'menu.classifications', icon: 'palette'},
        ]
    },
    {route: 'separator', permissions: ['all'], label: 'menu.bibliography', icon: 'bookmark',
        items: [
          {route: '/references', permissions: ['all'], label: 'menu.references', icon: 'bookmark'},
          {route: '/reference_sources', permissions: ['all'], label: 'menu.reference_sources', icon: 'bookmark'},
        ]
    },
    {route: 'separator', permissions: ['manage_users'], icon: 'gear',
        items: [
          {route: '/archives',              permissions: ['manage_users'],           label: 'menu.archives',               icon: 'box-archive'},
          {route: '/cards',                 permissions: ['manage_users'],           label: 'menu.cards',                  icon: 'file-lines'},
          {route: '/locations',             permissions: ['manage_users'],           label: 'menu.locations',              icon: 'location-dot'},
          {route: '/users',                 permissions: ['manage_users'],           label: 'menu.users',                  icon: 'users'},
          {route: '/files',                 permissions: ['manage_users'],           label: 'menu.medias',                 icon: 'photo-film'},
          {route: '/projects',              permissions: ['manage_projects'],        label: 'menu.projects',               icon: 'expand'},
          {route: '/tenants',               permissions: ['manage_tenants'],         label: 'menu.tenants',                icon: 'globe'},
          {route: '/logs',                  permissions: ['manage_users'],           label: 'menu.logs',                   icon: 'bug'},
          {route: '/communications',        permissions: ['manage_users'],           label: 'menu.communications',        icon: 'bullhorn'},
        ]
    },
  ],
  // menuItems: [
  //   {route: '/home',                  permissions: ['all'],                    label: 'menu.home',                   icon: 'chart-pie'},
  //   {route: 'separator',              permissions: ['all'],                    label: 'menu.persons_separator',      },
  //   {route: '/persons',               permissions: ['manage_registries', 'manage_persons'],                    label: 'menu.persons',                icon: 'user-tie'},
  //   {route: '/registries',            permissions: ['manage_registries'],      label: 'menu.registries',             icon: 'address-book'},
  //   {route: '/person_types',          permissions: ['manage_users'],           label: 'menu.person_types',           icon: 'user-tag'},
  //   {route: '/qualifications',        permissions: ['manage_users'],           label: 'menu.qualifications',         icon: 'user-graduate'},
  //   {route: '/location_person_types', permissions: ['manage_users'],           label: 'menu.location_person_types',  icon: 'house-user'},
  //   {route: '/card_person_types',     permissions: ['manage_users'],           label: 'menu.card_person_types',      icon: 'user-ninja'},
  //   {route: 'separator',              permissions: ['all'],                    label: 'menu.card_container_separator',      },
  //   {route: '/card_containers',       permissions: ['manage_projects', 'manage_card_containers'], label: 'menu.card_containers',        icon: 'layer-group'},
  //   {route: '/card_container_types',  permissions: ['manage_users'],           label: 'menu.card_container_types',   icon: 'bookmark'},
  //   {route: '/classifications',       permissions: ['manage_cards'],           label: 'menu.classifications',        icon: 'palette'},
  //   {route: '/tags',                  permissions: ['manage_cards'],           label: 'menu.tags',                   icon: 'tags'},
  //   {route: 'separator',              permissions: ['all'],                    label: '',      },
  //   {route: '/archives',              permissions: ['manage_users'],           label: 'menu.archives',               icon: 'box-archive'},
  //   {route: '/cards',                 permissions: ['manage_users'],           label: 'menu.cards',                  icon: 'file-lines'},
  //   {route: '/locations',             permissions: ['manage_users'],           label: 'menu.locations',              icon: 'location-dot'},
  //   {route: '/references',            permissions: ['all'],                    label: 'menu.references',             icon: 'bookmark'},
  //   {route: '/reference_sources',     permissions: ['all'],                    label: 'menu.reference_sources',      icon: 'bookmark'},
  //   {route: '/medias',                permissions: ['manage_users'],           label: 'menu.medias',                 icon: 'photo-film'},
  //   {route: '/users',                 permissions: ['manage_users'],           label: 'menu.users',                  icon: 'users'},
  //   {route: '/projects',              permissions: ['manage_projects'],        label: 'menu.projects',               icon: 'expand'},
  //   {route: '/tenants',               permissions: ['manage_tenants'],         label: 'menu.tenants',                icon: 'globe'},
  //   {route: '/logs',                  permissions: ['manage_users'],           label: 'menu.logs',                   icon: 'bug'},
  //   {route: '/communications',        permissions: ['manage_users'],           label: 'menu.communications',        icon: 'bullhorn'},
  // ],
  topBarMenuItems: [
    {route: 'https://www.sagas.unifi.it', external: true, label: 'SAGAS'},
    {route: 'https://www.ereditaculturali.sagas.unifi.it', external: true, label: 'Eredità Culturali'},
    {route: 'https://www.unifi.it', external: true, label: 'UniFi'},
  ],
  style: {
    topBarBackground: 'bg-blue-800',
    topBarLogo: "@/assets/images/sagas.svg"
  }
}