<template>
  <div :class="classes">
    <LoadingSpinner :toggle="isLoading" />
    <div  class="mt-2">
      <div class=" bg-slate-100 p-2 rounded-t-md flex flex-row justify-between">
        <div class="has-tooltip text-xs text-gray-400">
          <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 mb-10">{{ tip ? tip : $t('components.relations_manager.tips') }}</span>
          <f-icon icon="circle-question" />
        </div>
        <button class="bg-blue-600 px-3 py-2 text-xs text-white rounded ml-1" @click="addRelation">{{ addNewText ? addNewText : $t('components.relations_manager.add_relation') }}</button>
      </div>
      <div :class="'grid gap-2 mt-2 ' + (template === '1-1' ? 'grid-cols-2' : 'grid-cols-3') ">
        <div v-if="relations && relations.length > 0" :class="'flex flex-col col-span-' + (template === '2-1' ? '2' : '1')">
          <draggable v-model="relations" draggable=".item" @end="updateList">
            <div v-for="(relation, index) in relations" :key="'entity-location-'+index" class="item rounded bg-slate-100 px-3 py-2 mb-1 flex flex-row items-center">
              <div v-if="!disable" class="pr-2 flex flex-col justify-center cursor-move"><f-icon icon="sort" /></div>
              <div class="grow">
                <span class="text-base" v-html="labels.length > 0 ? getLabel(relation, labels) : relation.name"></span>
              </div>
              <button v-if="editing" @click="editRelation(index)" class="bg-blue-600 px-3 py-2 text-xs text-white rounded ml-1"><f-icon icon="pen-to-square" /></button>
              <button @click="removeRelation(relation)" class="bg-red-600 px-3 py-2 text-xs text-white rounded ml-1"><f-icon icon="circle-minus" /></button>
              <button v-if="insert" @click="removeRelated(relation)" class="bg-red-600 px-3 py-2 text-xs text-white rounded ml-1"><f-icon icon="trash-can" /></button>
            </div>
          </draggable>
        </div>
        <div v-else :class="'bg-slate-100 p-4 rounded-md col-span-' + (template === '2-1' ? '2' : '1')">
          <div>{{ $t('components.relations_manager.no_relations') }}</div>
        </div>
        <div :class="'flex flex-col col-span-' + (template === '1-2' ? '2' : '1')">
          <div v-if="canLoad" class="bg-slate-100 p-4 rounded-md h-full">
            <div v-if="isNew" class="bg-slate-200 p-4 rounded-md mb-4">
              <label :for="field.field" class="text-sm font-medium text-slate-700 flex gap-1">
                <div class="field-label">{{ $t('components.relations_manager.select_model') }}</div>
              </label>
              <VSelect
              v-model="related.id"
              :model="relatedModelApi || relatedModel"
              :extra-params="extraParams"
              :search-field="relatedSearchField"
              :label="relatedLabel"
              :labels="labels"
              value-field="id"
              :taggable="taggable"
              class="block w-full p-1 my-2 border text-xs border-slate-200 bg-white rounded ]focus:outline-none" />
              <div v-if="insert">
                <div class="text-sm font-medium py-2">{{ $t('components.relations_manager.add_or_select') }}</div>
                <label for="code" class="text-sm font-medium text-slate-700 flex gap-1">
                  <div class="field-label">{{ $t('views.cards.fields.code') }}</div>
                </label>
                <CrudInput
                v-model="related.card.code"
                :field="{ field:'code', type: 'text', name: $t('views.cards.fields.code') }"
                type="text"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline" />
                <label for="name" class="text-sm font-medium text-slate-700 flex gap-1">
                  <div class="field-label">{{ $t('views.cards.fields.name') }}</div>
                </label>
                <CrudInput
                v-model="related.card.name"
                :field="{ field:'name', type: 'text', name: $t('views.cards.fields.name') }"
                type="text"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline" />
              </div>
            </div>
            <div v-else>
              <div v-if="insert" class="bg-slate-200 p-4 rounded-md mb-4">
                <label for="code" class="text-sm font-medium text-slate-700 flex gap-1">
                  <div class="field-label">{{ $t('views.cards.fields.code') }}</div>
                </label>
                <CrudInput
                v-model="related.card.code"
                :field="{ field:'code', type: 'text', name: $t('views.cards.fields.code') }"
                type="text"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline" />
                <label for="name" class="text-sm font-medium text-slate-700 flex gap-1">
                  <div class="field-label">{{ $t('views.cards.fields.name') }}</div>
                </label>
                <CrudInput
                v-model="related.card.name"
                :field="{ field:'name', type: 'text', name: $t('views.cards.fields.name') }"
                type="text"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline" />
              </div>
              <div v-else class="text-lg text-slate-700 flex items-center mb-5" v-html="getLabel(related, labels)">
              </div>
            </div>
              
            <div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-${field.span || '1'} xl:col-span-${field.span || '1'}`" v-for="(field, index) in fields" :key="'fields-'+index">
              <label :for="field.field" class="text-sm font-medium text-slate-700 flex gap-1">
                <div class="field-label">{{ field.name }}</div>
                <div v-if="field.tip" class="has-tooltip text-xs text-slate-300">]
                  <span class="tooltip rounded shadow-lg p-1 bg-slate-100 text-slate-600 -mt-8">{{ field.tip }}</span>
                  <f-icon icon="circle-question" />
                </div>
              </label>
              <!-- Model Related Fields -->
              <div v-if="field.related">
                <!-- Input Type MULTI SELECT -->
                <div v-if="isNew">
                  <div class="px-3 py-2 bg-slate-200 rounded-md">{{ $t('tips.save_before') }}</div>
                </div>
                <div v-else>
                <VSelectMulti v-if="field.type === 'multi-select'" 
                  v-model="related[field.related][field.field]"
                  :model="field.model"
                  :search-field="field.label"
                  :label="field.label"
                  :labels="field.labels"
                  value-field="id"
                  :placeholder="$t('global.select')"
                  :taggable="field.taggable || false"
                  class="block w-full p-1 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none" />
                </div>
              </div>
              <!-- Simple Model Fields -->
              <div v-else>
                <!-- Input Type TEXTEDITOR -->
                <TextEditor
                  v-if="field.type === 'text-editor'"
                  v-model="related[field.field]"
                  classes-text="bg-white" />
                <CrudInput
                  v-if="field.type === 'text'"
                  v-model="related[field.field]"
                  :field="field"
                  :type="field.type"
                  classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline" />
                <!-- Input Type STATIC SELECT -->
                <select v-if="field.type === 'static-select'" v-model="related[field.field]" class="block w-full p-1 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none">
                  <option disabled value="">{{ $t('global.select') }}</option>
                  <option v-for="(option, index) in field.options" :key="`static-option-${index}`" :value="option.value">
                    {{ option.name }}
                  </option>
                </select>
                <!-- Input Type MULTI SELECT -->
                <VSelectMulti v-if="field.type === 'multi-select'" 
                  v-model="related[field.field]"
                  :model="field.model"
                  :search-field="field.label"
                  :label="field.label"
                  :labels="field.labels"
                  value-field="id"
                  :placeholder="$t('global.select')"
                  :taggable="field.taggable || false"
                  class="block w-full p-1 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none" />
                <!-- Input Type SELECT (API) -->
                <VSelect v-if="field.type === 'select'"
                  v-model="related[field.field]"
                  :model="field.model"
                  :search-field="field.label"
                  :label="field.label"
                  value-field="id"
                  :placeholder="$t('global.select')"
                  class="block w-full p-1 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none" />
                <!-- Input Type DATEPICKER -->
                <div v-if="field.type === 'date'" class="block w-full p-1 my-2 border text-sm text-black border-slate-200 bg-white rounded focus:outline-none">
                  <vue-date-picker v-model="related[field.field]" @change="handleChange($event, field, index)" value-type="YYYY-MM-DD" format="YYYY-MM-DD" input-class="px-2 py-1 outline-none" popup-class="" />
                </div>
              </div>
            </div>
            <div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-${field.span || '1'} xl:col-span-${field.span || '1'}`" v-for="(field, index) in pivots" :key="'pivot-'+index">
              <label :for="field.field" class="text-sm font-medium text-slate-700 flex gap-1">
                  <div class="field-label">{{ field.name }}</div>
                  <div v-if="field.tip" class="has-tooltip text-xs text-slate-300">]
                    <span class="tooltip rounded shadow-lg p-1 bg-slate-100 text-slate-600 -mt-8">{{ field.tip }}</span>
                    <f-icon icon="circle-question" />
                  </div>
                </label>
              <!-- Input Type TEXTEDITOR -->
              <TextEditor
                v-if="field.type === 'text-editor'"
                v-model="pivot[field.field]"
                classes-text="bg-white" />
              <CrudInput
                v-if="field.type === 'text'"
                v-model="pivot[field.field]"
                :field="field"
                :type="field.type"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline" />
              <!-- Input Type STATIC SELECT -->
              <select v-if="field.type === 'static-select'" v-model="pivot[field.field]" class="block w-full p-1 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none">
                <option disabled value="">{{ $t('global.select') }}</option>
                <option v-for="(option, index) in field.options" :key="`static-option-${index}`" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
              <!-- Input Type MULTI SELECT -->
              <VSelectMulti v-if="field.type === 'multi-select'" 
                v-model="pivot[field.field]" 
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                :labels="field.labels"
                value-field="id"
                :placeholder="$t('global.select')"
                class="block w-full p-1 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none" />
              <!-- Input Type SELECT (API) -->
              <VSelect v-if="field.type === 'select'"
                v-model="pivot[field.field]"
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                value-field="id"
                :placeholder="$t('global.select')"
                class="block w-full p-1 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none" />
              <!-- Input Type DATEPICKER -->
              <div v-if="field.type === 'date'" class="block w-full p-1 my-2 border text-sm text-black border-slate-200 bg-white rounded focus:outline-none">
                <vue-date-picker v-model="pivot[field.field]" @change="handleChange($event, field, index)" value-type="YYYY-MM-DD" format="YYYY-MM-DD" input-class="px-2 py-1 outline-none" popup-class="" />
              </div>
            </div>
            <button @click="saveRelation" class="bg-green-600 px-3 py-2 text-xs text-white rounded mt-2"><f-icon icon="floppy-disk" /> {{ $t('actions.save') }}</button>
          </div>
          <div v-else class="bg-slate-100 p-4 flex flex-col items-center justify-center rounded-md h-full">
            <div>{{ $t('components.relations_manager.select_relation') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import api from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner'
import ModalDelete from '@/components/ModalDelete'
import TextEditor from '@/components/TextEditor'
import CrudInput from '@/components/CrudInput'

export default {
  name: 'RelationsManager',
  components: {
    draggable, LoadingSpinner, TextEditor, CrudInput
  },
  props: {
    disable: {
      default: false
    },
    classes: {
      default: null
    },
    field: {
      default: null,
      required: true
    },
    id: {
      type: [String, Number],
      default: null,
      required: true
    },
    fields: {
      type: Array,
      default: () => [],
      required: true
    },
    model: {
      type: String,
      default: 'cards'
    },
    relatedModel: {
      type: String,
      default: 'persons'
    },
    relatedExtendedModel: {
      type: String,
      default: null
    },
    relatedExtendedModelApi: {
      type: String,
      default: null
    },
    relatedModelApi: {
      type: String,
      default: null
    },
    relatedSearchField: {
      type: String,
      default: 'name'
    },
    relatedLabel: {
      type: String,
      default: 'name'
    },
    pivots: {
      type: Array,
      default: () => [],
      required: false
    },
    pivotTitle: {
      type: String,
      default: 'Pivot fields'
    },
    ordering: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: true
    },
    addNewText: {
      type: String,
      default: null
    },
    tip: {
      type: String,
      default: null
    },
    labels: {
      type: Array,
      default: () => []
    },
    taggable: {
      type: Boolean,
      default: false
    },
    insert: {
      type: Boolean,
      default: false
    },
    template: {
      type: String,
      default: '2-1' // 1-2, 1-1
    },
    extraParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      disabled: false,
      relations: [],
      isNew: false,
      related: null,
      pivot: null,
      isLoading: false,
      canLoad: false
    }
  },
  async created(){
  },
  mounted(){
    this.loadRelations()
  },
  methods: {
    async loadRelations(){
      const vm = this
      vm.isLoading = true
      vm.canLoad = false
      vm.relations = [] 
      await api.fetch(`${vm.model}/${vm.id}/${vm.relatedModel}`, null, {'params': {'per_page': '999999'}}).then((response) => {
        //console.log('[loadRelations]', response.data)
        vm.relations = response.data
        vm.isLoading = false
      })
    },
    async removeRelation(related){
      const vm = this
      vm.$modal.show(ModalDelete,
        {
          title: vm.$i18n.t('components.relations_manager.remove_related'),
          text: vm.$i18n.t('components.relations_manager.remove_related_content'),
          deleteText: vm.$i18n.t('actions.remove'),
          cancelText: vm.$i18n.t('actions.cancel'),
          entity: related,
          model: `${vm.model}/${vm.id}/${vm.relatedModel}`,
          update: () => {
            vm.relations.splice(vm.relations.indexOf(related), 1)
          }
        },{
          height: 'auto'
        }
      )
    },
    async removeRelated(related){
      const vm = this
      console.log(related)
      if(related.cardable_id) related.id = related.cardable_id
      vm.$modal.show(ModalDelete,
        {
          title: vm.$i18n.t('components.relations_manager.delete_related'),
          text: vm.$i18n.t('components.relations_manager.delete_related_content'),
          deleteText: vm.$i18n.t('actions.remove'),
          cancelText: vm.$i18n.t('actions.cancel'),
          entity: related,
          model: `${vm.relatedExtendedModelApi}`,
          update: () => {
            vm.relations.splice(vm.relations.indexOf(related), 1)
          }
        },{
          height: 'auto'
        }
      )
    },
    editRelation(related){
      const vm = this
      vm.canLoad = false
      vm.isNew = false
      vm.related = vm.relations[related]
      vm.pivot = {}
      vm.$nextTick(()=>{
        if(vm.related.cardable) vm.fields.forEach(field => { 
          if(!field.related) vm.$set(vm.related, field.field, vm.related.cardable[field.field])
        })
        for(var j = 0; j < vm.pivots.length; j++) {
          vm.$set(vm.pivot, vm.pivots[j].field, vm.related.pivot[vm.pivots[j].field] || null)
        }
        if(vm.insert) vm.$set(vm.related, 'card', {
          code: vm.related.code, 
          name: vm.related.name,
          references: vm.related.references,
          reference_sources: vm.related.reference_sources,
        })
        vm.canLoad = true
      })
    }, 
    addRelation(){
      const vm = this
      vm.canLoad = false
      vm.isNew = true
      vm.related = {
        id: null
      }
      if(vm.insert) vm.related.card = { code: null, name: null, extended: vm.relatedExtendedModel }
      vm.pivot = {}
      vm.$nextTick(()=>{  
        for(var x = 0; x < vm.pivots.length; x++) {
          vm.$set(vm.pivot, vm.pivots[x].field, null)
        }
        vm.canLoad = true
      })
    },
    async saveRelation(){
      const vm = this
      vm.isLoading = true
      if(vm.isNew) {
        if(vm.related.id || (vm.insert && vm.related.card.code && vm.related.card.name)) {
          vm.$set(vm.related, 'pivot', vm.pivot)
          await api.create(`${vm.model}/${vm.id}/${vm.relatedModel}`, {lemma: vm.related}).then((response)=>{
            vm.isLoading = false
            if(response.success){
              vm.$toasted.success(vm.$t('components.relations_manager.success_created'))
              vm.isNew = false
              vm.canLoad = false
              vm.related = {}
              vm.loadRelations()
            }else{
              for(let error in response.errors){
                vm.errors[error] = response.errors[error]
                vm.$toasted.error(response.errors[error])
              }
            }
          })
        }else{
          vm.isLoading = false
          vm.$toasted.error(vm.$t('components.relations_manager.related_missing'))
        }
      }else{
        console.log('[update]', vm.isNew,vm.model, vm.id, {lemma: vm.related}, vm.relatedModel, vm.related.id)
        vm.$set(vm.related, 'pivot', vm.pivot)
        await api.update(vm.model, vm.id, {lemma: vm.related}, vm.relatedModel, vm.related.id).then((response) => {
          vm.canLoad = false
          vm.loadRelations()
          vm.isLoading = false
          if(response.success){
            vm.$toasted.success(vm.$t('components.relations_manager.success_update'))
          }else{
            for(let error in response.errors){
              vm.errors[error] = response.errors[error]
              vm.$toasted.error(response.errors[error])
            }
          }
        })
      }
    },
    async updateList(){
      const vm = this
      if(vm.ordering) {
        console.log('updateList!', vm.pluck(vm.relations, 'id'))
        await api.update(vm.model, vm.id, {relations: vm.pluck(vm.relations, 'id') }, vm.relatedModel+'-order').then((response) => {
          vm.isLoading = false
          if(response.success){
            vm.$toasted.success(vm.$t('components.relations_manager.success_order'))
          }else{
            vm.errors = []
            response.errors.forEach(error => {
              console.log(error)
              vm.errors.push(error)
              vm.$toasted.error(error)
            })
          }
        })
      }
    },
    pluck(arr, key) {
      return arr.map(i => i[key])
    },
    getLabel(relation, labels){
      let text = ''  
      let counter = 0
      let labeled = null
      labels.forEach(label => {
        labeled = relation[label]
        if(label.includes('pivot.')){
          labeled = relation.pivot[label.substring(6,label.length)]
        }
        if(labeled) {
          text += (counter === 0 ? '<span class="bg-primary text-sm text-white px-2 py-1 mr-2 rounded">' : '') + labeled + (counter === 0 ? '</span>' : ' ')
        }
        counter++
      })
      return text
    },
    handleChange(date, field, index){
      console.log(date, field, index)
      // const vm = this
      // vm.related.pivot[field] = date
    }
  }
}
</script>