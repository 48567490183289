var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f_cards"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.ereditaculturali.views.f_cards.single'))+" ")]},proxy:true},{key:"main",fn:function(){return [(_vm.canLoad)?_c('CrudEntity',{attrs:{"model":_vm.model,"id":_vm.id,"mode":_vm.crud_mode,"entity":_vm.entity,"is-extended-card":true,"extends":"card","has-location":true,"fields":[

          // Base Card fields
          {text: _vm.$t('views.cards.fields.card_separator'), type: 'separator'},
          {name: _vm.$t('views.cards.fields.code'), field: 'code', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.name'), field: 'name', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.ereditaculturali.tips.is_visible')},
          {name: _vm.$t('views.cards.fields.is_published'), field: 'is_published', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.ereditaculturali.tips.is_published')},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.archive'), field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
          {name: _vm.$t('views.cards.fields.user_id'), field: 'user_id', related: 'card', type: 'select', model: 'users', label: 'name', select: _vm.select_user },
          {name: _vm.$t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', labels: ['cards_count'], taggable: true},

          {text: _vm.$t('views.cards.persons.separator'), type: 'separator'},
          {
            field: 'persons', type: 'relations-manager', related: 'card', model: 'cards', relatedModel: 'persons', label: 'name', span: '4', taggable: false,
            pivots: [ 
              {name: _vm.$t('views.cards.persons.fields.card_person_type_id'), field: 'card_person_type_id', model: 'card_person_types',  label: 'name', type: 'select' },
              {name: _vm.$t('views.cards.persons.fields.description'), field: 'description', type: 'text-editor'}
            ],
            name: _vm.$t('views.cards.persons.name'), 
            title: _vm.$t('views.cards.persons.title'),
            addNewText: _vm.$t('views.cards.persons.add_new'),
            tip: _vm.$t('views.cards.persons.tip'),
            labeled: true
          },

          // Main fields        
          {text: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.f_card_separator'), type: 'separator'},    
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.inventario'), field: 'inventario', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.archivio'), field: 'archivio', type: 'text'},
          // {name: $t('plugins.ereditaculturali.views.f_cards.fields.fondo_collezione'), field: 'fondo_collezione', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.collection'), field: 'collection_id', type: 'select', model: 'collections', label: 'name' },
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.ente_produttore'), field: 'ente_produttore', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.foglio_IGM'), field: 'foglio_IGM', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.codice_luogo'), field: 'codice_luogo', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.strisciata'), field: 'strisciata', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.numero_inventario_patrimoniale'), field: 'numero_inventario_patrimoniale', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.collocazione'), field: 'collocazione', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.codice_univoco'), field: 'codice_univoco', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.definizione_oggetto'), field: 'definizione_oggetto', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.unita_archivistica'), field: 'unita_archivistica', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.indicazione_colore'), field: 'indicazione_colore', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.materia_tecnica_oggetto'), field: 'materia_tecnica_oggetto', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.misure_formato'), field: 'misure_formato', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.cronologia_generica'), field: 'cronologia_generica', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.cronologia_specifica'), field: 'cronologia_specifica', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.autore_fotografia'), field: 'autore_fotografia', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.editore'), field: 'editore', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.categorie'), field: 'categorie', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.soggetto'), field: 'soggetto', type: 'repeating-text'}, // ripetibile con |
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.titolo'), field: 'titolo', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.luogo_ripresa'), field: 'luogo_ripresa', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.data_ripresa'), field: 'data_ripresa', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.cronologia_opera_fotografata'), field: 'cronologia_opera_fotografata', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.autore_opera_fotografata'), field: 'autore_opera_fotografata', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.iscrizioni_timbri'), field: 'iscrizioni_timbri', type: 'text'},

          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.alterazioni_deterioramenti'), field: 'alterazioni_deterioramenti', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.data_restauro'), field: 'data_restauro', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.mostre'), field: 'mostre', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.numero_riproduzione'), field: 'numero_riproduzione', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.numero_fotografia_originale'), field: 'numero_fotografia_originale', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.collocazione_riproduzione_stampa'), field: 'collocazione_riproduzione_stampa', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.compilatore_scheda'), field: 'compilatore_scheda', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.funzionario_responsabile'), field: 'funzionario_responsabile', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.note_lavoro'), field: 'note_lavoro', type: 'text'},
          
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.bibliografia_specifica'), field: 'bibliografia_specifica', type: 'repeating-text', span: 4},

          // RV fields
          {text: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.rv_separator'), type: 'separator'},
          // setup related Card + pivot
          { // Card
            field: 'related_card_id', type: 'lemma', model: 'relateds', label: 'code', span: '2',
            pivots: [ 
              {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.type'), field: 'type', type: 'static-select', options: _vm.lists.vocabularies.relationships },
              {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.direction'), field: 'direction', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.description'), field: 'description', type: 'text-editor'},
              {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.notes'), field: 'notes', type: 'text'}
            ],
            name: _vm.$t('plugins.ereditaculturali.views.f_cards.related_card.name'), 
            title: _vm.$t('plugins.ereditaculturali.views.f_cards.related_card.title'),
            addNewText: _vm.$t('plugins.ereditaculturali.views.f_cards.related_card.add_new'),
            tip: _vm.$t('plugins.ereditaculturali.views.f_cards.related_card.tip'),
            labeled: true
          },

          // CM fields
          {text: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.cm_separator'), type: 'separator'},
          { // ScanCertification
            field: 'f_certification_id', type: 'related', model: 'f_certifications', label: 'name', span: '4',
            fields: [ 
              {name: _vm.$t('plugins.ereditaculturali.views.f_cards.f_certifications.fields.cmr'),     field: 'cmr', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.f_cards.f_certifications.fields.cma'),     field: 'cma', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.f_cards.f_certifications.fields.adp'),     field: 'adp', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.f_cards.f_certifications.fields.oss'),     field: 'oss', type: 'text'},
            ],
            name: _vm.$t('plugins.ereditaculturali.views.f_cards.f_certifications.title'),
            add: _vm.$t('plugins.ereditaculturali.views.f_cards.f_certifications.add')
          },    

          {text: _vm.$t('plugins.ereditaculturali.views.f_cards.location.title'), type: 'separator'},
          {name: _vm.$t('components.locations_manager.title'), field: 'locations', related: 'card', model: 'cards', type: 'location-manager', span: '4',
            fields: [ 
              {name: _vm.$t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.classification_id'), field: 'classification_id', span: '2', type: 'select', model: 'classifications', label: 'name' },
              {name: _vm.$t('views.locations.fields.location'), field: 'location', type: 'location', span: '4'},
              {name: _vm.$t('views.locations.fields.polygonz'), field: 'polygonz', type: 'geometry', geometry: 'polygonz', mode: 'map', span: '4'},
            ],
            pivots: [ 
              {name: _vm.$t('views.cards.locations.fields.type'), field: 'type', type: 'text', span: '2'},
              {name: _vm.$t('views.cards.locations.fields.description'), field: 'description', type: 'text', span: '2'},
            ],
            pivotTitle: _vm.$t('views.persons.locations.pivot')
          },

          // Location fields
          // {name: $t('views.locations.fields.name'),         related: 'card', subrelated: 'location', field: 'name', type: 'text', span: '2'},
          // {name: $t('views.locations.fields.address'),      related: 'card', subrelated: 'location', field: 'address', type: 'text', span: '2'},
          // {name: $t('views.locations.fields.accuracy'),     related: 'card', subrelated: 'location', field: 'accuracy', type: 'text', span: '2'},
          // {name: $t('views.locations.fields.classification_id'), related: 'card', subrelated: 'location', field: 'classification_id', span: '2', type: 'select', model: 'classifications', label: 'name' },
          // // {name: $t('views.locations.fields.location'),     related: 'card', subrelated: 'location', field: 'location', type: 'location', span: '2'},
          
          // {name: $t('views.locations.fields.pointz'),       related: 'card', subrelated: 'location', field: 'pointz', type: 'geometry', mode: 'map', digitizeType: 'marker',  geometry: 'pointz', span: '4'},

          // {name: $t('views.locations.fields.multipointz'),  related: 'card', subrelated: 'location', field: 'multipointz', type: 'geometry', geometry: 'multipointz', span: '2'},
          // {name: $t('views.locations.fields.polygonz'),     related: 'card', subrelated: 'location', field: 'polygonz', type: 'geometry',    geometry: 'polygonz', span: '2'},
          // {name: $t('views.locations.fields.linestringz'),  related: 'card', subrelated: 'location', field: 'linestringz', type: 'geometry', geometry: 'linestringz', span: '2'},
          // {name: $t('views.locations.fields.polygon'),      related: 'card', subrelated: 'location', field: 'polygon', type: 'geometry',     geometry: 'polygon', span: '2'},       
        ]}}):_vm._e(),(_vm.id)?_c('div',{staticClass:"mt-2"},[(_vm.canLoad)?_c('FileManager',{attrs:{"mode":_vm.crud_mode,"model-id":parseInt(_vm.id),"model":_vm.model,"entity-data":_vm.entity,"related":true,"uploaderText":_vm.$t('plugins.ereditaculturali.views.f_cards.uploader_text'),"file-types-allowed":[
            {name: 'PNG', type: 'image/png'},
            {name: 'JPG', type: 'image/jpeg'},
            {name: 'TIFF', type: 'image/tiff'},
            {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
            {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {name: 'OBJ', type: 'text/plain'},
            {name: 'PDF', type: 'application/pdf'}
          ],"file-model-types":[
            {type: 'document', name: _vm.$t('views.cards.files.types.document'),  fields: ['description']},
            {type: 'raster',   name: _vm.$t('views.cards.files.types.raster'),    fields: ['description', 'ne_lat', 'ne_lng', 'sw_lat', 'sw_lng', 'elevation']},
            {type: 'image', name: _vm.$t('views.cards.files.types.image'),  fields: ['description']},
            {type: 'photo', name: _vm.$t('views.cards.files.types.photo'),  fields: ['description']},
            {type: 'threed_model', name: _vm.$t('views.cards.files.types.threed_model'), fields: ['description', 'file_id']},
            {type: 'threed_texture', name: _vm.$t('views.cards.files.types.threed_texture'), fields: ['description', 'file_id']},
            {type: 'spheric_picture', name: _vm.$t('views.cards.files.types.spheric_picture'), fields: ['description']},
          ],"file-model-fields":[
            {name: _vm.$t('views.cards.files.fields.description'), field: 'description', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.crs'), field: 'crs', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lat'), field: 'ne_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lng'), field: 'ne_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lat'), field: 'sw_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lng'), field: 'sw_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.file_id'), field: 'file_id', type: 'select', related: 'files', label: 'id', placeholder: _vm.$t('views.cards.files.fields.file_select')},
            {name: _vm.$t('views.cards.files.fields.elevation'), field: 'elevation', type: 'text'},
          ]}}):_vm._e()],1):_vm._e(),(_vm.canLoad)?_c('EntityInfo',{attrs:{"entity":_vm.entity}}):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }